<template>
    <div>
        <!-- <el-select v-model="value" placeholder="请选择" @change="changeVal" style="width:260px;">
            <el-option v-for="item in options" :key="item.id" :label="item.address" :value="item.id">
            </el-option>
        </el-select> -->
        <el-radio-group v-model="value" @change="changeVal" style="width: 100%;">
            <el-radio v-for="item in options" :key="item.id" :label="item.id">{{ item.address }}</el-radio>
        </el-radio-group>
        <el-button style="margin-left: 15px;" type="primary" icon="el-icon-plus" circle size="mini"
            @click="dialogFormVisible = true"></el-button>
        <el-dialog title="添加地址" :visible.sync="dialogFormVisible" :wrapper-closable="false" :close-on-click-modal="false">
            <div id="billing-form" class="mb--40">


                <div class="row">

                    <div class="col-md-6 col-12 mb--20">
                        <label>收貨人*</label>
                        <input type="text" placeholder="收貨人姓名" v-model="form.consignee">
                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>Email*</label>
                        <input type="email" placeholder="Email Address" v-model="form.email">
                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>手機號碼*</label>
                        <input type="text" placeholder="Phone number" v-model="form.mobile">
                    </div>


                    <div class="col-12 mb--20">
                        <label>詳細地址*</label>
                        <input type="text" placeholder="Address line 1" v-model="form.address">

                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>Country*</label>
                        <select class="nice-select" v-model="form.country">

                            <option>China</option>

                        </select>
                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>Town/City*</label>
                        <input type="text" placeholder="Town/City" v-model="form.city">
                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>State*</label>
                        <input type="text" placeholder="State" v-model="form.province">
                    </div>

                    <div class="col-md-6 col-12 mb--20">
                        <label>Zip Code*</label>
                        <input type="text" placeholder="Zip Code" v-model="form.zipCode">
                    </div>


                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="new_address">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as api from "@/api/api"
export default {
    created() {
        this.get_data();
    },
    props: {
        'selectType': {
            type: Number,
            Default: 0
        }
    },
    watch: {
        'selectType': {
            immediate: true,
            handler(newVal) {
                console.log("pp" + newVal)
                if (newVal != 0) {
                    this.selectType = newVal
                    this.value = newVal
                }


            },
            deep: true
        },

    },
    data() {
        return {
            options: [],
            value: "",
            preset: "",
            dialogFormVisible: false,
            form: {}
        }
    },
    methods: {
        get_data() {
            var params = {
                userToken: sessionStorage.getItem("uid")
            }
            api.getRequest('/user/myAddress/',
                params).then(resp => {
                    console.log(resp.data)

                    this.options = resp.data.records
                    if (this.selectType == 0) {
                        this.value = parseInt(this.options[0].id)
                    }

                })
        },
        changeVal(val) {
            var res = null
            console.log(val)
            this.options.forEach(function (i) {
                if (i.id == val) {
                    res = i
                }
            })
            console.log(this.options)
            this.$emit('selectContent', val);

            this.$bus.$emit('sel_add', res)

        },
        new_address() {
            this.form.userToken = sessionStorage.getItem("uid")

            console.log(this.form)
            api.postRequest('/user/newAddress', this.form).then(resp => {
                console.log("res" + resp.data)

                if (resp.data.res == 1) {
                    this.$message.success(resp.message);
                    this.get_data();
                    this.dialogFormVisible = false

                } else {
                    this.$message.error(resp.message);
                }
            })
        }


    }
}
</script>