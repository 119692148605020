import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

// import 'element-ui/lib/element-#DC7F8D/index.css';
import 'element-ui/lib/element-#4F4AA7/index.css';
import router from './router'
////数据
import axios from 'axios'
import shop from './api/shop'

axios.defaults.baseURL = 'https://api.ifamoushk.com/api/'
// axios.defaults.baseURL = 'http://localhost:8091/api/'

const service = axios.create({
  baseURL: process.env.VUE_APP_API, // 请求的接口
  timeout: 100000 // 请求超时时间
})

Vue.prototype.$http = service
Vue.prototype.$axios = axios
Vue.prototype.$shop = shop
Vue.prototype.$bus = new Vue()
import md5 from 'md5';
Vue.prototype.$md5 = md5;


Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'tc', // 语言包
  messages: {
    'cn': require('./lang/cn.json'), // 中文简体
    'en': require('./lang/en.json'), // 英文
    'tc': require('./lang/tc.json') // 繁体
  }
});
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  i18n, router,
  render: h => h(App),
  data: function () {
    return {
      clientName: "ifamous ",
      clientLink: " ifamoushk.com",
      startYear: "2017",
      serverUrl: "https://api.ifamoushk.com/upload_file/",
      section: ["中西区", "东区", "南区", "湾仔区", "九龙城区", "观塘区", "深水埗区", "黄大仙区", "油尖旺区", "离岛区", "葵青区", "北区", "西贡区", "沙田区", "大埔区", "荃湾区", "屯门区", "元朗区", "将军澳", "坑口", "大围", "火炭", "红磡", "马鞍山", "何文田"],

    }
  }
}).$mount('#app')
