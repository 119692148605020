<template>
    <div>
        <!-- breadcrumb area start -->
        <div class="breadcrumb-area common-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-wrap">
                            <nav aria-label="breadcrumb">
                                <h1>{{ showTitle }}</h1>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                    <li class="breadcrumb-item active" aria-current="page">購物 </li>
                                    <li class="breadcrumb-item active">{{ showTitle }}</li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- breadcrumb area end -->

        <div class="shop-main-wrapper section-space">
            <div class="container">
                <div class="row">
                    <!-- product details wrapper start -->
                    <div class="col-lg-12 order-1 order-lg-2">
                        <!-- product details inner end -->
                        <div class="product-details-inner">
                            <div class="row">
                                <div class="col-lg-5">
                                    <prd_zoom :images="pics"></prd_zoom>

                                </div>
                                <div class="col-lg-7">
                                    <div class="product-details-des">
                                        <h3 class="product-name">{{ detail.productName }}</h3>
                                        <!-- <div class="ratings d-flex">
                                        <span><i class="lnr lnr-star"></i></span>
                                        <span><i class="lnr lnr-star"></i></span>
                                        <span><i class="lnr lnr-star"></i></span>
                                        <span><i class="lnr lnr-star"></i></span>
                                        <span><i class="lnr lnr-star"></i></span>
                                        <div class="pro-review">
                                            <span>1 Reviews</span>
                                        </div>
                                    </div> -->
                                        <div class="price-box">
                                            <span class="price-regular">${{ detail.price }}</span>
                                            <!-- <span class="price-old"><del>$90.00</del></span> -->
                                        </div>
                                        <!-- <h5 class="offer-text"><strong>Hurry up</strong>! offer ends in:</h5>
                                    <div class="product-countdown" data-countdown="2019/07/25"></div> -->
                                        <!-- <div class="availability">
                                        <i class="fa fa-check-circle"></i>
                                        <span>200 in stock</span>
                                    </div> -->
                                        <p class="pro-desc">{{ detail.intro }}</p>
                                        <div class="quantity-cart-box d-flex align-items-center">
                                            <h5>qty:</h5>
                                            <div class="quantity">
                                                <div class="pro-qty">
                                                    <!-- <el-input-number size="mini" v-model="qty"></el-input-number>-->
                                                    <span class="dec qtybtn" @click="changeQty(-1)">-</span><input
                                                        type="text" value="1" v-model="qty" @change="changeQty"><span
                                                        class="inc qtybtn" @click="changeQty(1)">+</span>
                                                </div>
                                            </div>
                                            <div class="action_link">
                                                <a class="btn btn-cart2" href="javascript:;" @click="addCart(detail)">Add to
                                                    cart</a>
                                            </div>
                                        </div>
                                        <!-- <div class="pro-size">
                                        <h5>size :</h5>

                                        <el-select v-model="value" placeholder="请选择">
                                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div> -->
                                        <!-- <div class="color-option">
                                        <h5>color :</h5>
                                        <ul class="color-categories">
                                            <li>
                                                <a class="c-lightblue" href="javascript:;" title="LightSteelblue"></a>
                                            </li>
                                            <li>
                                                <a class="c-darktan" href="javascript:;" title="Darktan"></a>
                                            </li>
                                            <li>
                                                <a class="c-grey" href="javascript:;" title="Grey"></a>
                                            </li>
                                            <li>
                                                <a class="c-brown" href="javascript:;" title="Brown"></a>
                                            </li>
                                        </ul>
                                    </div> -->
                                        <div class="useful-links">

                                            <a href="javascript:;" data-toggle="tooltip" title="Wishlist"
                                                @click="addFavorite(detail)"><i class="lnr lnr-heart"></i>wishlist</a>
                                        </div>
                                        <!-- <div class="like-icon">
                                        <a class="facebook" href="javascript:;"><i class="fa fa-facebook"></i>like</a>
                                        <a class="twitter" href="javascript:;"><i class="fa fa-twitter"></i>tweet</a>
                                        <a class="pinterest" href="javascript:;"><i class="fa fa-pinterest"></i>save</a>
                                        <a class="google" href="javascript:;"><i class="fa fa-google-plus"></i>share</a>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- product details inner end -->

                        <!-- product details reviews start -->
                        <div class="product-details-reviews section-space pb-0">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="product-review-info">
                                        <ul class="nav review-tab">
                                            <li>
                                                <a class="active" data-toggle="tab" href="#tab_one">description</a>
                                            </li>

                                        </ul>
                                        <div class="tab-content reviews-tab">
                                            <div class="tab-pane fade show active" id="tab_one">
                                                <div class="tab-one">
                                                    <p>{{ detail.intro }}</p>
                                                </div>
                                            </div>
                                            <!-- <div class="tab-pane fade" id="tab_two">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>color</td>
                                                        <td>black, blue, red</td>
                                                    </tr>
                                                    <tr>
                                                        <td>size</td>
                                                        <td>L, M, S</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- product details reviews end -->
                    </div>
                    <!-- product details wrapper end -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as api from "../api/api"
import prd_zoom from "@/components/prd_zoom.vue"
export default {
    components: {
        prd_zoom
    },
    data() {
        return {
            sellan: "cn",
            setId: "",
            detail: {},
            qty: 1,
            pics: [],
            showTitle: ""


        }
    },
    mounted() {



        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }


    },
    created() {
        this.setId = this.$route.params.id
        this.get_data(this.$route.params.id)
    },

    watch: {
        'qty': {
            immediate: true,
            handler(newVal) {

                // console.log(newVal)
            },
            deep: true
        },

    },
    methods: {

        setLang(val) {
            // console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        switchLang(val) {
            this.setLang(val)
        },
        get_data(id) {
            if (id != undefined) {
                api.getRequest('/products/' + id,
                    {}).then(resp => {

                        // console.log(resp.data)
                        this.detail = resp.data

                        if (this.sellan == 'tc') {
                            this.detail.productName = this.detail.productNameTc
                        }
                        if (this.sellan == 'en') {
                            this.detail.productName = this.detail.productNameEn
                        }
                        this.showTitle = this.detail.productName
                        // this.detail.showPics = api.return_pics_json(api.return_jason(this.detail.showPics))
                        this.pics = this.detail.pics
                        // console.log(this.detail.pics)

                    })
            }


        },
        addCart(item) {

            item.quantity = this.qty
            this.$shop.addCart(item)
            this.$bus.$emit('cart', item.quantity);
        },
        addFavorite(item) {
            this.$shop.addFavorite(item)
            this.$bus.$emit('favorite', 1);
        },

        changeQty(val) {
            this.qty = this.qty + val
        },
        handleChange() {
            // console.log(this.selected)

            // this.size = event.target.value
            // console.log(this.size)
        },




    }
}
</script>