<template>
    <div>
        <top_head @switchLang="switchLang"></top_head>

        <!-- off-canvas menu start -->
        <aside class="off-canvas-wrapper">
            <div class="off-canvas-overlay"></div>
            <div class="off-canvas-inner-content">
                <div class="btn-close-off-canvas">
                    <i class="lnr lnr-cross"></i>
                </div>

                <div class="off-canvas-inner">
                    <!-- search box start -->
                    <div class="search-box-offcanvas">
                        <form>
                            <input type="text" placeholder="Search Here...">
                            <button class="search-btn"><i class="lnr lnr-magnifier"></i></button>
                        </form>
                    </div>
                    <!-- search box end -->

                    <!-- mobile menu start -->
                    <div class="mobile-navigation">

                        <!-- mobile menu navigation start -->
                        <nav>
                            <ul class="mobile-menu">
                                <li><a href="index.html">Home</a></li>
                                <li><a href="shop.html">Shop</a></li>
                                <li><a href="#/productDetail">Product Details</a></li>
                            </ul>
                        </nav>
                        <!-- mobile menu navigation end -->
                    </div>
                    <!-- mobile menu end -->

                    <div class="mobile-settings">
                        <ul class="nav">
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="currency" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Currency
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="currency">
                                        <a class="dropdown-item" href="#">$ USD</a>
                                        <a class="dropdown-item" href="#">$ EURO</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="myaccount" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        My Account
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="myaccount">
                                        <a class="dropdown-item" href="#">my account</a>
                                        <a class="dropdown-item" href="#"> login</a>
                                        <a class="dropdown-item" href="#">register</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- offcanvas widget area start -->
                    <div class="offcanvas-widget-area">
                        <div class="off-canvas-contact-widget">
                            <ul>
                                <li><i class="fa fa-mobile"></i>
                                    <a href="#">0123456789</a>
                                </li>
                                <li><i class="fa fa-envelope-o"></i>
                                    <a href="#">info@yourdomain.com</a>
                                </li>
                            </ul>
                        </div>
                        <div class="off-canvas-social-widget">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-pinterest-p"></i></a>
                            <a href="#"><i class="fa fa-linkedin"></i></a>
                            <a href="#"><i class="fa fa-youtube-play"></i></a>
                        </div>
                    </div>
                    <!-- offcanvas widget area end -->
                </div>
            </div>
        </aside>
        <!-- off-canvas menu end -->

        <!-- main wrapper start -->
        <main>
            <!-- breadcrumb area start -->
            <div class="breadcrumb-area common-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-wrap">
                                <nav aria-label="breadcrumb">
                                    <h1>shop</h1>
                                    <ul class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                        <li class="breadcrumb-item active" aria-current="page">shop</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- breadcrumb area end -->
            <!-- page main wrapper start -->
            <div class="shop-main-wrapper section-space pb-0">
                <div class="container">
                    <div class="row">

                        <!-- sidebar area start -->
                        <prd_type :lang="sellan" @select_type="select_type"></prd_type>
                        <!-- sidebar area end -->
                        <!-- shop main wrapper start -->
                        <div class="col-lg-9 order-1 order-lg-2">
                            <div class="shop-product-wrapper">
                                <!-- shop product top wrap start -->
                                <div class="shop-top-bar">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7 col-md-6 order-2 order-md-1">
                                            <div class="top-bar-left">
                                                <div class="product-view-mode">
                                                    <a class="active" href="#" data-target="grid-view" data-toggle="tooltip"
                                                        title="Grid View"><i class="fa fa-th"></i></a>
                                                    <a href="#" data-target="list-view" data-toggle="tooltip"
                                                        title="List View"><i class="fa fa-list"></i></a>
                                                </div>
                                                <div class="product-amount">
                                                    <p>Showing {{ form_rec }}–{{ to_rec }} of {{ totalRecord }} results</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6 order-1 order-md-2">
                                            <div class="top-bar-right">
                                                <div class="product-short">
                                                    <p>Sort By : </p>
                                                    <select class="nice-select" name="sortby">
                                                        <option value="trending">Relevance</option>
                                                        <option value="sales">Name (A - Z)</option>
                                                        <option value="sales">Name (Z - A)</option>
                                                        <option value="rating">Price (Low &gt; High)</option>
                                                        <option value="date">Rating (Lowest)</option>
                                                        <option value="price-asc">Model (A - Z)</option>
                                                        <option value="price-asc">Model (Z - A)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- shop product top wrap start -->

                                <!-- product item list wrapper start -->
                                <div class="shop-product-wrap grid-view row mbn-40">
                                    <!-- product single item start -->
                                    <div class="col-md-4 col-sm-6" v-for="item in products">
                                        <!-- product grid start -->
                                        <div class="product-item">
                                            <figure class="product-thumb">
                                                <a href="javascript:;" @click="goto(item.id)">{{ item.productName }}

                                                    <img class="pri-img" :src="item.showPics[0]" alt="product">
                                                    <img class="sec-img" :src="item.showPics[1]" alt="product"
                                                        v-if="item.showPics[1] != null">
                                                </a>
                                                <div class="product-badge">
                                                    <div class="product-label new">
                                                        <span>new</span>
                                                    </div>
                                                    <div class="product-label discount">
                                                        <span>10%</span>
                                                    </div>
                                                </div>
                                                <div class="button-group">
                                                    <a href="#" data-toggle="tooltip" data-placement="left"
                                                        title="Add to wishlist"><i class="lnr lnr-heart"></i></a>
                                                    <a href="#" data-toggle="modal" data-target="#quick_view"><span
                                                            data-toggle="tooltip" data-placement="left"
                                                            title="Quick View"><i class="lnr lnr-magnifier"></i></span></a>
                                                    <a href="#" data-toggle="tooltip" data-placement="left"
                                                        @click="addCart(item)" title="Add to Cart"><i
                                                            class="lnr lnr-cart"></i></a>
                                                </div>
                                            </figure>
                                            <div class="product-caption">
                                                <p class="product-name">
                                                    <a href="javascript:;" @click="goto(item.id)">{{ item.productName }}</a>
                                                </p>
                                                <div class="price-box">
                                                    <span class="price-regular">${{ item.price }}</span>
                                                    <span class="price-old"><del>$50.00</del></span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- product grid end -->

                                        <!-- product list item end -->
                                        <div class="product-list-item">
                                            <figure class="product-thumb">
                                                <a href="#/productDetail">
                                                    <img class="pri-img" src="assets/img/product/product-1.jpg"
                                                        alt="product">
                                                    <img class="sec-img" src="assets/img/product/product-3.jpg"
                                                        alt="product">
                                                </a>
                                                <div class="product-badge">
                                                    <div class="product-label new">
                                                        <span>new</span>
                                                    </div>
                                                    <div class="product-label discount">
                                                        <span>10%</span>
                                                    </div>
                                                </div>
                                            </figure>
                                            <div class="product-content-list">
                                                <h5 class="product-name"><a href="#/productDetail">Flowers daisy pink
                                                        stick</a></h5>
                                                <div class="price-box">
                                                    <span class="price-regular">$50.00</span>
                                                    <span class="price-old"><del>$60.00</del></span>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde
                                                    perspiciatis
                                                    quod numquam, sit fugiat, deserunt ipsa mollitia sunt quam corporis
                                                    ullam
                                                    rem, accusantium adipisci officia eaque.
                                                </p>
                                                <div class="button-group-list">
                                                    <a class="btn-big" href="#" data-toggle="tooltip" title="Add to Cart"><i
                                                            class="lnr lnr-cart"></i>Add to Cart</a>
                                                    <a href="#" data-toggle="modal" data-target="#quick_view"><span
                                                            data-toggle="tooltip" title="Quick View"><i
                                                                class="lnr lnr-magnifier"></i></span></a>
                                                    <a href="#" data-toggle="tooltip" title="Add to wishlist"><i
                                                            class="lnr lnr-heart"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- product list item end -->
                                    </div>
                                    <!-- product single item start -->











                                </div>
                                <!-- product item list wrapper end -->

                                <!-- start pagination area -->
                                <div class="paginatoin-area text-center">
                                    <el-pagination background layout="prev, pager, next" :total="totalRecord">
                                    </el-pagination>
                                </div>
                                <!-- end pagination area -->
                            </div>
                        </div>
                        <!-- shop main wrapper end -->
                    </div>
                </div>
            </div>
            <!-- page main wrapper end -->
            <!-- Quick view modal start -->
            <div class="modal" id="quick_view">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <!-- product details inner end -->
                            <div class="product-details-inner">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5">
                                        <div class="product-large-slider">
                                            <div class="pro-large-img">
                                                <img src="assets/img/product/product-details-img1.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-large-img">
                                                <img src="assets/img/product/product-details-img2.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-large-img">
                                                <img src="assets/img/product/product-details-img3.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-large-img">
                                                <img src="assets/img/product/product-details-img4.jpg"
                                                    alt="product-details" />
                                            </div>
                                        </div>
                                        <div class="pro-nav slick-row-10 slick-arrow-style">
                                            <div class="pro-nav-thumb">
                                                <img src="assets/img/product/product-details-img1.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-nav-thumb">
                                                <img src="assets/img/product/product-details-img2.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-nav-thumb">
                                                <img src="assets/img/product/product-details-img3.jpg"
                                                    alt="product-details" />
                                            </div>
                                            <div class="pro-nav-thumb">
                                                <img src="assets/img/product/product-details-img4.jpg"
                                                    alt="product-details" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <div class="product-details-des quick-details">
                                            <h3 class="product-name">Orchid flower white stick</h3>
                                            <div class="ratings d-flex">
                                                <span><i class="lnr lnr-star"></i></span>
                                                <span><i class="lnr lnr-star"></i></span>
                                                <span><i class="lnr lnr-star"></i></span>
                                                <span><i class="lnr lnr-star"></i></span>
                                                <span><i class="lnr lnr-star"></i></span>
                                                <div class="pro-review">
                                                    <span>1 Reviews</span>
                                                </div>
                                            </div>
                                            <div class="price-box">
                                                <span class="price-regular">$70.00</span>
                                                <span class="price-old"><del>$90.00</del></span>
                                            </div>
                                            <h5 class="offer-text"><strong>Hurry up</strong>! offer ends in:</h5>
                                            <div class="product-countdown" data-countdown="2019/08/25"></div>
                                            <div class="availability">
                                                <i class="fa fa-check-circle"></i>
                                                <span>200 in stock</span>
                                            </div>
                                            <p class="pro-desc">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                diam nonumy
                                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
                                            <div class="quantity-cart-box d-flex align-items-center">
                                                <h5>qty:</h5>
                                                <div class="quantity">
                                                    <div class="pro-qty"><input type="text" value="1"></div>
                                                </div>
                                                <div class="action_link">
                                                    <a class="btn btn-cart2" href="#">Add to cart</a>
                                                </div>
                                            </div>
                                            <div class="useful-links">
                                                <a href="#" data-toggle="tooltip" title="Compare"><i
                                                        class="lnr lnr-sync"></i>compare</a>
                                                <a href="#" data-toggle="tooltip" title="Wishlist"><i
                                                        class="lnr lnr-heart"></i>wishlist</a>
                                            </div>
                                            <div class="like-icon">
                                                <a class="facebook" href="#"><i class="fa fa-facebook"></i>like</a>
                                                <a class="twitter" href="#"><i class="fa fa-twitter"></i>tweet</a>
                                                <a class="pinterest" href="#"><i class="fa fa-pinterest"></i>save</a>
                                                <a class="google" href="#"><i class="fa fa-google-plus"></i>share</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- product details inner end -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Quick view modal end -->
            <foot></foot>
        </main>
        <!-- main wrapper end -->

    </div>
</template>
<script>
import top_head from "@/components/top_head.vue"
import * as api from "@/api/api"
import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
import prd_type from "@/components/prd_type.vue"
export default {
    components: {
        top_head,
        Lang,
        foot,
        prd_type
    },
    data() {
        return {
            sellan: "cn",
            drawer: false,
            banner_height: '510px',
            products: [],
            types: [],
            totalRecord: 0,
            currentpage: 1,
            pagesize: 12,
            form_rec: 1,
            to_rec: 1
        }
    },
    created() {
        this.get_product()
        this.actvie()
    },
    mounted() {

        // localStorage.setItem("lang", "cn")
        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }
        window.addEventListener('resize', this.handleResize)


    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)

            document.title = this.$t('index.title')// 
        },
        goto(page) {
            this.$router.push(page);
            // location.reload();
        },
        get_product(val) {
            var prdType = ""
            if (val) {
                prdType = val
            }
            api.getRequest('/products',
                {
                    keywords: "", prdType: prdType, topage: this.currentpage,
                    pagesize: this.pagesize
                }).then(resp => {
                    this.products = resp.data.records
                    this.totalRecord = resp.data.total
                    this.products.forEach(function (item) {
                        item.showPics = api.return_pics_json(api.return_jason(item.showPics))
                    })
                    this.form_rec = 1 + this.pagesize * (this.currentpage - 1)
                    var to_rec = this.form_rec + this.pagesize
                    if (to_rec >= this.totalRecord) {
                        to_rec = this.totalRecord
                    }
                    this.to_rec = to_rec
                    console.log(this.products)
                })
        },
        addCart(item) {
            this.$shop.addCart(item)
        },
        select_type(val) {
            this.get_product(val)
        },

        switchLang(val) {
            this.setLang(val)
        },

        goto(id) {
            this.$router.push("productDetail/" + id)
        },


        actvie() {
            /*------ Sticky menu start ------*/
            var $window = $(window);
            $window.on('scroll', function () {
                var scroll = $window.scrollTop();
                if (scroll < 300) {
                    $(".sticky").removeClass("is-sticky");
                } else {
                    $(".sticky").addClass("is-sticky");
                }
            });
            /*------ Sticky menu end ------*/




            /*------- Image zoom effect start -------*/
            $('.img-zoom').zoom();
            /*------- Image zoom effect end -------*/

            /*------- tooltip active js start -------*/
            $('[data-toggle="tooltip"]').tooltip();
            /*------- tooltip active js start -------*/





            /*-------- prodct details slider active start --------*/
            $('.product-large-slider').slick({
                fade: true,
                arrows: false,
                asNavFor: '.pro-nav'
            });

            // product details slider nav active
            $('.pro-nav').slick({
                slidesToShow: 4,
                asNavFor: '.product-large-slider',
                centerMode: true,
                centerPadding: 0,
                focusOnSelect: true,
                prevArrow: '<button type="button" class="slick-prev"><i class="lnr lnr-chevron-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="lnr lnr-chevron-right"></i></button>',
                responsive: [{
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        arrows: false
                    }
                }]
            });
            /*-------- prodct details slider active end --------*/

            /*------ Latest blog carousel active start ------*/
            $('.latest-blog-carousel').slick({
                autoplay: false,
                speed: 1000,
                slidesToShow: 3,
                adaptiveHeight: true,
                prevArrow: '<button type="button" class="slick-prev"><i class="lnr lnr-chevron-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="lnr lnr-chevron-right"></i></button>',
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        arrows: false
                    }
                }]
            });
            /*------ Latest blog carousel active end ------*/

            /*------ Hero main slider active start ------*/
            $('.blog-carousel-active').slick({
                autoplay: true,
                fade: true,
                speed: 1000,
                dots: false,
                slidesToShow: 1,
                prevArrow: '<button type="button" class="slick-prev"><i class="lnr lnr-chevron-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="lnr lnr-chevron-right"></i></button>'
            });
            /*------ Hero main slider active end ------*/

            /*------- offcanvas search form active start -------*/
            $(".offcanvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".offcanvas-search-inner").addClass('show')
            })

            $(".minicart-btn").on('click', function () {
                $("body").addClass('fix');
                $(".minicart-inner").addClass('show')
            })

            $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".offcanvas-search-inner, .minicart-inner").removeClass('show')
            })
            /*------- offcanvas search form active end -------*/


            /*------- nice select active start -------*/
            $('select').niceSelect();
            /*------- nice select active end -------*/

            /*-------- Off Canvas Open close start--------*/
            $(".off-canvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".off-canvas-wrapper").addClass('open');
            });

            $(".btn-close-off-canvas,.off-canvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".off-canvas-wrapper").removeClass('open');
            });
            /*-------- Off Canvas Open close end--------*/

            // pricing filter
            var rangeSlider = $(".price-range"),
                amount = $("#amount"),
                minPrice = rangeSlider.data('min'),
                maxPrice = rangeSlider.data('max');
            rangeSlider.slider({
                range: true,
                min: 0,
                max: 1200,
                values: [100, 1000],
                slide: function (event, ui) {
                    amount.val("$" + ui.values[0] + " - $" + ui.values[1]);
                }
            });
            amount.val(" $" + rangeSlider.slider("values", 0) +
                " - $" + rangeSlider.slider("values", 1));


            /*------- product view mode change js start -------*/
            $('.product-view-mode a').on('click', function (e) {
                e.preventDefault();
                var shopProductWrap = $('.shop-product-wrap');
                var viewMode = $(this).data('target');
                $('.product-view-mode a').removeClass('active');
                $(this).addClass('active');
                shopProductWrap.removeClass('grid-view list-view').addClass(viewMode);
            })
            /*------- product view mode change js end -------*/

            /*------- Countdown Activation start -------*/
            $('[data-countdown]').each(function () {
                var $this = $(this),
                    finalDate = $(this).data('countdown');
                $this.countdown(finalDate, function (event) {
                    $this.html(event.strftime('<div class="single-countdown"><span class="single-countdown__time">%D</span><span class="single-countdown__text">Days</span></div><div class="single-countdown"><span class="single-countdown__time">%H</span><span class="single-countdown__text">Hours</span></div><div class="single-countdown"><span class="single-countdown__time">%M</span><span class="single-countdown__text">Mins</span></div><div class="single-countdown"><span class="single-countdown__time">%S</span><span class="single-countdown__text">Secs</span></div>'));
                });
            });
            /*------- Countdown Activation end -------*/

            /*--------- quantity change js start ---------*/
            $('.pro-qty').prepend('<span class="dec qtybtn">-</span>');
            $('.pro-qty').append('<span class="inc qtybtn">+</span>');
            $('.qtybtn').on('click', function () {
                var $button = $(this);
                var oldValue = $button.parent().find('input').val();
                if ($button.hasClass('inc')) {
                    var newVal = parseFloat(oldValue) + 1;
                } else {
                    // Don't allow decrementing below zero
                    if (oldValue > 0) {
                        var newVal = parseFloat(oldValue) - 1;
                    } else {
                        newVal = 0;
                    }
                }
                $button.parent().find('input').val(newVal);
            });
            /*--------- quantity change js end ---------*/

            /*-------- scroll to top start --------*/
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 600) {
                    $('.scroll-top').removeClass('not-visible');
                } else {
                    $('.scroll-top').addClass('not-visible');
                }
            });
            $('.scroll-top').on('click', function (event) {
                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
            });
            /*-------- scroll to top end --------*/

            /*------- offcanvas mobile menu start -------*/
            var $offCanvasNav = $('.mobile-menu'),
                $offCanvasNavSubMenu = $offCanvasNav.find('.dropdown');

            /*Add Toggle Button With Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i></i></span>');

            /*Close Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.slideUp();

            /*Category Sub Menu Toggle*/
            $offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
                var $this = $(this);
                if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
                    e.preventDefault();
                    if ($this.siblings('ul:visible').length) {
                        $this.parent('li').removeClass('active');
                        $this.siblings('ul').slideUp();
                    } else {
                        $this.parent('li').addClass('active');
                        $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                        $this.closest('li').siblings('li').find('ul:visible').slideUp();
                        $this.siblings('ul').slideDown();
                    }
                }
            });
            /*------- offcanvas mobile menu end -------*/

            /*------- Checkout Page accordion start -------*/
            $("#create_pwd").on("change", function () {
                $(".account-create").slideToggle("100");
            });

            $("#ship_to_different").on("change", function () {
                $(".ship-to-different").slideToggle("100");
            });

            // Payment Method Accordion
            $('input[name="paymentmethod"]').on('click', function () {
                var $value = $(this).attr('value');
                $('.payment-method-details').slideUp();
                $('[data-method="' + $value + '"]').slideDown();
            });
            /*------- Checkout Page accordion start -------*/

            // slide effect dropdown
            function dropdownAnimation() {
                $('.toggle-bar').on('show.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideDown(5000);
                });

                $('.toggle-bar').on('hide.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideUp(5000);
                });
            }
            dropdownAnimation();


            // User Changeable Access
            var activeId = $("#instafeed"),
                myTemplate = '<div class="instagram-item"><a href="{{link}}" target="_blank" id="{{id}}"><img src="{{image}}" /></a><div class="instagram-hvr-content"><span class="tottallikes"><i class="fa fa-heart"></i>{{likes}}</span><span class="totalcomments"><i class="fa fa-comments"></i>{{comments}}</span></div></div>';

            if (activeId.length) {
                var userID = activeId.attr('data-userid'),
                    accessTokenID = activeId.attr('data-accesstoken'),

                    userFeed = new Instafeed({
                        get: 'user',
                        userId: userID,
                        accessToken: accessTokenID,
                        resolution: 'standard_resolution',
                        template: myTemplate,
                        sortBy: 'least-recent',
                        limit: 15,
                        links: false
                    });
                userFeed.run();
            }

            // Instagram feed carousel active
            $(window).on('load', function () {
                var instagramFeed = $(".instagram-carousel");
                instagramFeed.imagesLoaded(function () {
                    instagramFeed.slick({
                        slidesToShow: 6,
                        slidesToScroll: 2,
                        dots: false,
                        arrows: false,
                        responsive: [{
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 4,
                            }
                        }
                        ]
                    })
                });
            })
        }
    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}



.el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4F4AA7;
    color: #FFF;
}
</style>