<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style></style>
