<template>
    <div>
        <top_head></top_head>



        <!-- main wrapper start -->
        <main>

            <keep-alive>
                <router-view :key="$route.fullPath"></router-view>
            </keep-alive>


            <foot></foot>
        </main>
        <!-- main wrapper end -->

    </div>
</template>
<script>
import top_head from "@/components/top_head.vue"
import * as api from "@/api/api"
import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
import prd_type from "@/components/prd_type.vue"
export default {
    components: {
        top_head,
        Lang,
        foot,
        prd_type
    },
    data() {
        return {
            sellan: "cn",
            drawer: false,
            banner_height: '510px',
            products: [],
            types: [],
            totalRecord: 0,
            currentpage: 1,
            pagesize: 12,
            form_rec: 1,
            to_rec: 1,
            pathTxt: ""
        }
    },
    mounted() {

        this.$bus.$on('languageChanged', (language) => {

            this.sellan = language;
        });
        this.$bus.$on('pathTxt', (pathTxt) => {
            this.pathTxt = pathTxt
            console.log(pathTxt)
        });

        this.actvie()

    },

    methods: {

        goto(page) {
            this.$router.push(page);
            // location.reload();
        },

        select_type(val) {
            this.get_product(val)
        },

        switchLang(val) {
            this.setLang(val)
        },


        actvie() {
            /*------ Sticky menu start ------*/
            var $window = $(window);
            $window.on('scroll', function () {
                var scroll = $window.scrollTop();
                if (scroll < 300) {
                    $(".sticky").removeClass("is-sticky");
                } else {
                    $(".sticky").addClass("is-sticky");
                }
            });
            /*------ Sticky menu end ------*/













            /*------- offcanvas search form active start -------*/
            $(".offcanvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".offcanvas-search-inner").addClass('show')
            })

            $(".minicart-btn").on('click', function () {
                $("body").addClass('fix');
                $(".minicart-inner").addClass('show')
            })

            $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".offcanvas-search-inner, .minicart-inner").removeClass('show')
            })
            /*------- offcanvas search form active end -------*/


            /*------- nice select active start -------*/
            $('select').niceSelect();
            /*------- nice select active end -------*/

            /*-------- Off Canvas Open close start--------*/
            $(".off-canvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".off-canvas-wrapper").addClass('open');
            });

            $(".btn-close-off-canvas,.off-canvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".off-canvas-wrapper").removeClass('open');
            });
            /*-------- Off Canvas Open close end--------*/


            /*------- product view mode change js start -------*/
            $('.product-view-mode a').on('click', function (e) {
                e.preventDefault();
                var shopProductWrap = $('.shop-product-wrap');
                var viewMode = $(this).data('target');
                $('.product-view-mode a').removeClass('active');
                $(this).addClass('active');
                shopProductWrap.removeClass('grid-view list-view').addClass(viewMode);
            })
            /*------- product view mode change js end -------*/




            /*-------- scroll to top start --------*/
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 600) {
                    $('.scroll-top').removeClass('not-visible');
                } else {
                    $('.scroll-top').addClass('not-visible');
                }
            });
            $('.scroll-top').on('click', function (event) {
                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
            });
            /*-------- scroll to top end --------*/

            /*------- offcanvas mobile menu start -------*/
            var $offCanvasNav = $('.mobile-menu'),
                $offCanvasNavSubMenu = $offCanvasNav.find('.dropdown');

            /*Add Toggle Button With Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i></i></span>');

            /*Close Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.slideUp();

            /*Category Sub Menu Toggle*/
            $offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
                var $this = $(this);
                if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
                    e.preventDefault();
                    if ($this.siblings('ul:visible').length) {
                        $this.parent('li').removeClass('active');
                        $this.siblings('ul').slideUp();
                    } else {
                        $this.parent('li').addClass('active');
                        $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                        $this.closest('li').siblings('li').find('ul:visible').slideUp();
                        $this.siblings('ul').slideDown();
                    }
                }
            });
            /*------- offcanvas mobile menu end -------*/

            /*------- Checkout Page accordion start -------*/
            $("#create_pwd").on("change", function () {
                $(".account-create").slideToggle("100");
            });

            $("#ship_to_different").on("change", function () {
                $(".ship-to-different").slideToggle("100");
            });



            // slide effect dropdown
            function dropdownAnimation() {
                $('.toggle-bar').on('show.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideDown(5000);
                });

                $('.toggle-bar').on('hide.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideUp(5000);
                });
            }
            dropdownAnimation();


            // User Changeable Access
            var activeId = $("#instafeed"),
                myTemplate = '<div class="instagram-item"><a href="{{link}}" target="_blank" id="{{id}}"><img src="{{image}}" /></a><div class="instagram-hvr-content"><span class="tottallikes"><i class="fa fa-heart"></i>{{likes}}</span><span class="totalcomments"><i class="fa fa-comments"></i>{{comments}}</span></div></div>';

            if (activeId.length) {
                var userID = activeId.attr('data-userid'),
                    accessTokenID = activeId.attr('data-accesstoken'),

                    userFeed = new Instafeed({
                        get: 'user',
                        userId: userID,
                        accessToken: accessTokenID,
                        resolution: 'standard_resolution',
                        template: myTemplate,
                        sortBy: 'least-recent',
                        limit: 15,
                        links: false
                    });
                userFeed.run();
            }

            // Instagram feed carousel active
            $(window).on('load', function () {
                var instagramFeed = $(".instagram-carousel");
                instagramFeed.imagesLoaded(function () {
                    instagramFeed.slick({
                        slidesToShow: 6,
                        slidesToScroll: 2,
                        dots: false,
                        arrows: false,
                        responsive: [{
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 4,
                            }
                        }
                        ]
                    })
                });
            })
        }
    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}



.el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4F4AA7;
    color: #FFF;
}
</style>