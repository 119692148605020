<template>
    <div class="main-wrapper">
        <!-- My Account Page -->
        <div class="my-account-area ptb--120 ptb_md--80 ptb_sm--80 bg-color" data-bg-color="#ffffff">
            <div class="container">
                <div class="row">
                    <!-- Login Form -->
                    <div class="col-lg-6">
                        <div class="login-form-wrapper">
                            <form action="#" class="sn-form sn-form-boxed">
                                <div class="sn-form-inner">
                                    <div class="single-input">
                                        <label for="login-form-email">{{ $t('login.txt5') }} *</label>
                                        <input type="text" name="login-form-email" id="login-form-email"
                                            v-model="login_form.username">
                                    </div>
                                    <div class="single-input">
                                        <label for="login-form-password">{{ $t('login.txt3') }} *</label>
                                        <input type="password" name="login-form-password" id="login-form-password"
                                            v-model="login_form.password">
                                    </div>
                                    <div class="single-input">
                                        <button type="button" class="mr-3" @click="login">
                                            <span>{{ $t('login.txt6') }}</span>
                                        </button>
                                        <button type="button" class="mr-3">
                                            <span>{{ $t('login.txt9') }}</span>
                                        </button>
                                        <button type="button" class="mr-3">
                                            <span>{{ $t('login.txt10') }}</span>
                                        </button>
                                        <div class="checkbox-input">
                                            <input type="checkbox" name="login-form-remember" id="login-form-remember">
                                            <label for="login-form-remember">Remember me</label>
                                        </div>
                                    </div>
                                    <div class="single-input">
                                        <a href="#">{{ $t('login.txt7') }}</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--// Login Form -->

                    <!-- Register Form -->
                    <div class="col-lg-6 mt_md--30 mt_sm--30">
                        <div class="login-form-wrapper">
                            <form class="sn-form sn-form-boxed">
                                <div class="sn-form-inner">
                                    <div class="single-input">
                                        <label for="register-form-name">{{ $t('login.txt1') }}</label>
                                        <input type="text" name="register-form-name" id="register-form-name"
                                            v-model="regist.username">
                                    </div>
                                    <div class="single-input">
                                        <label for="register-form-email">{{ $t('login.txt2') }}</label>
                                        <input type="text" name="register-form-email" id="register-form-email"
                                            v-model="regist.email">
                                    </div>
                                    <div class="single-input">
                                        <label for="register-form-password">{{ $t('login.txt3') }}</label>
                                        <input type="password" name="register-form-password" id="register-form-password"
                                            v-model="regist.password">
                                    </div>
                                    <div class="single-input">
                                        <label for="register-form-email">{{ $t('login.txt8') }}</label>
                                        <input type="text" name="register-form-email" id="register-form-email"
                                            v-model="regist.shareCode">
                                    </div>
                                    <div class="single-input">
                                        <button type="button" @click="regist_account">
                                            <span>{{ $t('login.txt4') }}</span>
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--// Register Form -->
                </div>
            </div>
        </div>
        <!--// My Account Page -->

    </div>
</template>
<script>
import * as api from "../api/api"
export default {
    components: {

    },
    data() {
        return {
            sellan: "cn",
            section: "story",
            showpic: "images/contact.jpg",
            message: "",
            email: "",
            phone: "",
            name: "",
            subject: "",
            regist: {
                username: "",
                email: "",
                password: "",
                shareCode: ""
            },
            login_form: {
                username: "",
                password: ""
            }
        }
    },
    mounted() {


        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }

    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        switchLang(val) {
            this.setLang(val)
        },
        regist_account() {
            this.regist.password = this.$md5(this.regist.password)
            var form = this.regist
            console.log(form)
            api.getRequest('/user/regist',
                form).then(resp => {
                    console.log(resp)
                    this.$message.success("Registed successful!");
                })
        },
        login() {
            this.login_form.password = this.$md5(this.login_form.password)
            var form = this.login_form
            console.log(form)
            api.getRequest('/user/login',
                form).then(resp => {
                    console.log(resp)
                    if (resp.data.isUser == 1 && resp.data.isLogged == 0) {
                        this.$message.error('密码错误');
                    }
                    if (resp.data.isUser == 0 && resp.data.isLogged == 0) {
                        this.$message.error('用户名不存在');
                    }
                    if (resp.data.isUser == 1 && resp.data.isLogged == 1) {
                        this.$message.success('登录成功');
                        sessionStorage.setItem("token", resp.data.token)
                        sessionStorage.setItem("uid", resp.data.uid)
                        sessionStorage.setItem("cart", resp.data.cart)
                        sessionStorage.setItem("favorite", resp.data.favorite)
                        this.$router.push('/myAccount')


                    }

                })
        }
    }
}
</script>
<style></style>