<template>
    <div class="main-wrapper">
        <!-- breadcrumb area start -->
        <div class="breadcrumb-area common-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-wrap">
                            <nav aria-label="breadcrumb">
                                <h1>Shopping Cart</h1>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                    <li class="breadcrumb-item active" aria-current="page">購物車</li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- breadcrumb area end -->
        <!-- Cart Page Start -->
        <div class="cart_area pt--120 pb--80 bg-color pt_md--80 pt_sm--80" data-bg-color="#ffffff">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <!-- Cart Table -->
                        <div class="cart-table table-responsive mb--40">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="pro-thumbnail">Image</th>
                                        <th class="pro-title">Product</th>
                                        <th class="pro-price">Price</th>
                                        <th class="pro-quantity">Quantity</th>
                                        <th class="pro-subtotal">Total</th>
                                        <th class="pro-remove">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item, index in  myCart " :key="index">
                                        <td class="pro-thumbnail"><a :href="'#/productDetail/' + item.id"><img
                                                    :src="item.showPics" alt="Product"></a>
                                        </td>
                                        <td class="pro-title"><a :href="'#/productDetail/' + item.id"
                                                v-if="sellan == 'cn'">{{
                                                    item.prdName }}</a>
                                            <a :href="'#/productDetail/' + item.id" v-if="sellan == 'tc'">{{ item.prdNameTc
                                            }}</a>
                                            <a :href="'#/productDetail/' + item.id" v-if="sellan == 'en'">{{ item.prdNameEn
                                            }}</a>
                                        </td>
                                        <td class="pro-price"><span>${{ item.price }}</span></td>
                                        <td class="pro-price">
                                            <el-input-number size="mini" v-model="item.quantity" :min="1"
                                                @change="edti_qty(item)"></el-input-number>

                                        </td>
                                        <td class="pro-subtotal"><span>${{ item.price * item.quantity }}</span></td>
                                        <td class="pro-remove"><a href="javascript:;" @click="delete_cart(item.id)"><i
                                                    class="fa fa-trash-o"></i></a></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>


                        <div class="row">
                            <div class="col-lg-6 col-12 mb--15">
                                <!-- Calculate Shipping -->
                                <div class="calculate-shipping">
                                    <h4>Calculate Shipping</h4>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-md-6 col-12 mb--25">
                                                <el-select v-model="value" placeholder="请选择">
                                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                        :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="col-md-6 col-12 mb--25">
                                                <el-select v-model="selectSection" placeholder="请选择">
                                                    <el-option v-for="item in section" :key="item" :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <!-- Discount Coupon -->
                                <div class="discount-coupon">
                                    <h4>Discount Coupon Code</h4>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-md-6 col-12 mb--25">
                                                <input type="text" placeholder="Coupon Code">
                                            </div>
                                            <div class="col-md-6 col-12 mb--25">
                                                <input type="submit" value="Apply Code">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Cart Summary -->
                            <div class="col-lg-6 col-12 mb--40 d-flex">
                                <div class="cart-summary">
                                    <div class="cart-summary-wrap">
                                        <h4>Cart Summary</h4>
                                        <p>Sub Total <span>${{ total }}</span></p>
                                        <p>Shipping Cost <span>${{ fee }}</span></p>
                                        <h2>Grand Total <span>${{ grand }}</span></h2>
                                    </div>
                                    <div class="cart-summary-button">
                                        <button class="checkout-btn" @click="checkout">Checkout</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Cart Page End -->
    </div>
</template>
<script>
import * as api from "../api/api"
export default {
    data() {
        return {
            sellan: "tc",
            // lang: 0,
            drawer: false,
            columns: [],
            langTitle: [
                {
                    code: "en",
                    title: 'English',
                },
                {
                    code: "tc",
                    title: '繁体中文',
                }
            ],
            langSet: "繁体中文",
            openItem: -1,
            showBtn: window.innerWidth <= 767,
            activeIndex: '1',
            myCart: [],
            total: 0,
            fee: 0,
            grand: 0,
            options: [{
                value: '选项1',
                label: '香港'
            },],
            section: this.$root.section,
            value: '',
            selectSection: ""
        }
    },
    created() {
        this.$shop.isLogin()
        this.my_cart()
        this.sellan = localStorage.getItem('lang')


    },
    methods: {


        my_cart() {
            if (sessionStorage.getItem("uid")) {
                api.getRequest('/myCart',
                    { userToken: sessionStorage.getItem("uid") }).then(resp => {
                        console.log(resp)
                        if (resp.data.rs != -1) {
                            this.myCart = resp.data.data
                            this.total = resp.data.total
                            this.grand = this.total - this.fee
                            this.drawer = true
                        } else {
                            this.$message.info("请先登录");
                        }



                    })
            } else {
                this.$message.info("请先登录");
            }
        },
        delete_cart(id) {

            this.$confirm('删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/cart/' + id,
                    {}).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                        this.my_cart()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        edti_qty() {
            console.log(this.myCart)
            var total = 0
            var cart = 0
            this.myCart.forEach(function (i) {
                cart += i.quantity
                total += i.quantity * i.price
            })
            this.total = total
            this.grand = total - this.fee
            sessionStorage.setItem('cart', cart)
        },

        checkout() {

            var checkout = {
                cart: this.myCart,
                total: this.total,
                grand: this.grand,
                fee: this.fee

            }

            console.log(checkout)
            localStorage.setItem("cart", JSON.stringify(checkout))
            console.log(localStorage.getItem("cart"))
            this.$router.push('/checkout');
        }

    }
}
</script>