<template>
    <div>
        <!-- breadcrumb area start -->
        <div class="breadcrumb-area common-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-wrap">
                            <nav aria-label="breadcrumb">
                                <h1>{{ title }}</h1>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                    <li class="breadcrumb-item active">{{ title }}</li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- breadcrumb area end -->

        <div class="shop-main-wrapper section-space">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <p v-html="content"></p>
                    </div>
                    <div class="col-lg-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as api from "../api/api"
import foot from "@/components/foot.vue"
export default {
    components: {

    },
    data() {
        return {
            sellan: "tc",
            section: "",
            showpic: "images/hero.jpg",
            content: "",
            title: ""
        }
    },
    created() {
        this.section = this.$route.params.id
        this.setLang(this.sellan)

        this.get_data()
    },
    mounted() {



    },
    methods: {
        setLang(val) {

            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        get_data() {

            if (this.section !== "" && this.section != undefined) {
                api.getRequest('/contents/' + this.section,
                    {}).then(resp => {


                        this.content = resp.data.detail
                        this.title = resp.data.title

                        if (this.sellan == 'en') {
                            this.content = resp.data.detailEn
                            this.title = resp.data.titleEn
                        }
                        if (this.sellan == 'tc') {
                            this.content = resp.data.detailTc
                            this.title = resp.data.titleTc
                        }
                        if (this.sellan == 'cn') {
                            this.content = resp.data.detail
                            this.title = resp.data.title
                        }
                    })
            }

        },

    }
}
</script>