<template>
    <div style="color: #fff;width: 200px;height: 30px;position: absolute;top: 0;right: 5%;z-index: 100;"><a
            href="javascript:;" @click="setLang('cn')" class="lang-btn">中文</a>
        | <a href="javascript:;" @click="setLang('en')" class="lang-btn">EN</a>
        | <a href="javascript:;" @click="setLang('sp')" class="lang-btn">ES</a>
    </div>
</template>
<script>

export default {

    data() {
        return {
            sellan: "cn",
        }
    },
    mounted() {

        // localStorage.setItem("lang", "cn")
        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }

    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
            this.$emit("switchLang", val)
        }
    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
</style>