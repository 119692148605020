import Vue from "vue";
import VueRouter from "vue-router";


import index from "@/views/index"
import modal from "@/views/modal"
import shop from "@/views/shop"
import shopping from "@/views/shopping"

import empty_page from "../views/empty_page"
import login from "@/views/login.vue"
import my_account from "@/views/my_account"
import detail from "@/views/product_detail"
import checkout from "@/views/checkout"
import cart from "@/views/cart"
import content from "@/views/content"
import contact from "@/views/contact"
import favorite from "@/views/favorite"
import supplier from "@/views/supplier.vue";
import partner from "@/views/partner.vue";
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      keepAlive: false
    },
    component: index,
  },
  {
    path: "/modal",
    name: "index",
    meta: {
      keepAlive: false
    },
    component: modal,
  },
  {
    path: "/empty",
    name: "empty",
    meta: {
      title: '标准页'
    },
    component: empty_page,
    children: [
      {
        path: '/login',
        component: login,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/supplier',
        component: supplier,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/partner',
        component: partner,
        meta: {
          keepAlive: true
        }
      },

      {
        path: '/myAccount',
        component: my_account,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/shopping/:type',
        component: shopping,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/productDetail/:id',
        component: detail,
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/checkout',
        component: checkout,
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/cart',
        component: cart,
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/content/:id',
        component: content,
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/contact',
        component: contact,
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/favorite',
        component: favorite,
        meta: {
          keepAlive: false
        }
      },






    ]
  },
  {
    path: "/shop",
    name: "shop",
    meta: {
      keepAlive: false
    },
    component: shop,
  },


]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // const lang = localStorage.getItem("lang") || 'cn'
    // console.log(lang)
    // VueI18n.locale = lang
    // document.title = to.meta.title[VueI18n.locale];
  }
  next();
})



export default router
