<template>
    <div>
        <div class="goods-image">
            <!-- 预览大图 -->
            <div class="large" v-show="show" :style="[{ backgroundImage: `url(${images[currIndex]})` }, bgPosition]">
            </div>
            <!-- 商品大图 -->
            <div class="middle">
                <!-- 商品大图 -->
                <img ref="target" :src="images[currIndex]" alt="" />
                <!-- 鼠标进入图片的遮罩 -->
                <div class="layer" v-show="show" :style="[position]"></div>
            </div>


        </div>
        <!-- 缩略小图 -->
        <div class="show-box">
            <div style="position: absolute; width: 1200px;">
                <div :class="i == currIndex ? 'list-pic act' : 'list-pic'" v-for=" (img, i) in images" :key="i">
                    <img @mouseenter="currIndex = i" :src="img" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, watch } from 'vue'
import { useMouseInElement } from '@vueuse/core'
export default {
    components: {

    },
    data() {
        return {
            prdPic_width: "1200px "


        }
    },
    props: {
        images: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        'images': {
            immediate: true,
            handler(newVal) {
                this.images = newVal
            },
            deep: true
        },

    },
    setup(props) {
        // 控制预览大图与遮罩层的显示与隐藏
        const show = ref(false)
        // 控制商品大图显示那张图片
        const currIndex = ref(0)
        // ref 获取 DOM 元素
        const target = ref(null)
        // 记录遮罩半透明图在商品大图中的坐标位置
        const position = reactive({
            top: 0,
            left: 0
        })
        // 记录遮罩层做覆盖的区域在预览时的预览大图的坐标位置
        const bgPosition = reactive({
            backgroundPositionX: 0,
            backgroundPositionY: 0
        })
        // 第三方 vueuse 提供的方法 useMouseInElement 获取鼠标在某块区域的坐标
        const { elementX, elementY, isOutside } = useMouseInElement(target)
        // 侦听器监测鼠标进入商品大图时的坐标从而操纵遮罩层和预览大图的效果
        watch([elementX, elementY, isOutside], () => {
            // isisOutside.value 为 true 的时候代表鼠标未进入目标元素，为 false 时代表鼠标进入目标元素
            // 为 true 是不记录坐标以免损耗性能
            if (isOutside.value) {
                // 鼠标未进入目标元素不显示遮罩层和预览大图
                show.value = false
                return
            }
            // 鼠标进入目标元素显示遮罩层和预览大图
            show.value = true
            // 判断边界值，根据遮罩层大小、商品图片大小判断
            // left 的边界值（左、右）
            if (elementX.value < 100) {
                position.left = 0
            } else if (elementX.value > 300) {
                position.left = 200
            } else {
                position.left = elementX.value - 100
            }
            // top 的边界值（上、下）
            if (elementY.value < 100) {
                position.top = 0
            } else if (elementY.value > 300) {
                position.top = 200
            } else {
                position.top = elementY.value - 100
            }
            // 遮罩层所覆盖的商品图片部分在预览大图中的坐标，加单位
            bgPosition.backgroundPositionY = -position.top * 2 + 'px'
            bgPosition.backgroundPositionX = -position.left * 2 + 'px'
            // 遮罩层相对于商品大图左上角坐标，加单位
            position.top += 'px'
            position.left += 'px'
        })
        // 返回给模板使用
        return { currIndex, show, target, position, bgPosition }
    },
    mounted() {




    },
    methods: {




    }
}
</script>
<style  >
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.goods-image {
    width: 490px;
    height: 510px;
    position: relative;
    display: flex;

}

.large {
    position: absolute;
    top: 0;
    left: 495px;
    width: 400px;
    height: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    background-color: #f8f8f8;
    z-index: 100;
}

.show-box {
    position: relative;
    width: 470px;
    height: 110px;
    overflow: hidden;
}

.middle {
    width: 472px;
    height: 472px;
    position: relative;
    cursor: move;
    border: 1px solid #dfdede;

    .layer {
        width: 200px;
        height: 200px;
        background: rgba(0, 0, 0, 0.2);
        left: 0;
        top: 0;
        position: absolute;
    }
}

.middle img {
    width: 470px;
    height: 470px;
}


.small-pic li {
    width: 100px;
    height: 100px;
    margin-left: 12px;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid #dfdede;
}

.list-pic {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #dfdede;
}

.list-pic:hover {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #4F4AA7;
}

.act {
    border: 1px solid #4F4AA7;
}
</style>