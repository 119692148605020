<template>
    <div>
        <!-- breadcrumb area start -->
        <div class="breadcrumb-area common-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-wrap">
                            <nav aria-label="breadcrumb">
                                <h1>{{ showTitle }}</h1>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                    <li class="breadcrumb-item active" aria-current="page">購物 </li>
                                    <li class="breadcrumb-item active">{{ showTitle }}</li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- breadcrumb area end -->

        <div class="shop-main-wrapper section-space pb-0">

            <div class="container">
                <div class="row">

                    <!-- sidebar area start -->
                    <prd_type :lang="sellan" @select_type="select_type" @price_range="price_range"></prd_type>
                    <!-- sidebar area end -->
                    <!-- shop main wrapper start -->
                    <div class="col-lg-9 order-1 order-lg-2">
                        <div class="shop-product-wrapper">
                            <!-- shop product top wrap start -->
                            <div class="shop-top-bar">
                                <div class="row align-items-center">
                                    <div class="col-lg-7 col-md-6 order-2 order-md-1">
                                        <div class="top-bar-left">
                                            <div class="product-view-mode">
                                                <a class="active" href="javascript:;" data-target="grid-view"
                                                    data-toggle="tooltip" title="Grid View"><i class="fa fa-th"></i></a>
                                                <a href="javascript:;" data-target="list-view" data-toggle="tooltip"
                                                    title="List View"><i class="fa fa-list"></i></a>
                                            </div>
                                            <div class="product-amount">
                                                <p>{{ $t("shop.show") }} {{ form_rec }}–{{ to_rec }} {{ $t("shop.of") }} {{
                                                    totalRecord }} {{ $t("shop.results") }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-6 order-1 order-md-2">
                                        <div class="top-bar-right">
                                            <div class="product-short">
                                                <p>{{ $t("shop.sort") }} : </p>
                                                <el-select v-model="sortby" style="width: 160px" slot="prepend"
                                                    @change="selSort" placeholder="">
                                                    <el-option value="1" :label="$t('shop.relevance')"></el-option>
                                                    <el-option value="2" :label="$t('shop.name') + '(A - Z)'"></el-option>
                                                    <el-option value="3" :label="$t('shop.name') + '(Z - A)'"></el-option>
                                                    <el-option value="4"
                                                        :label="$t('shop.price') + $t('shop.lowhi')"></el-option>
                                                    <el-option value="5"
                                                        :label="$t('shop.price') + $t('shop.hilow')"></el-option>

                                                    <el-option value="6" :label="$t('shop.modal') + ' (A - Z)'"></el-option>
                                                    <el-option value="7" :label="$t('shop.modal') + ' (Z - A)'"></el-option>
                                                </el-select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- shop product top wrap start -->

                            <!-- product item list wrapper start -->
                            <div class="shop-product-wrap grid-view row mbn-40">
                                <!-- product single item start -->
                                <div class="col-md-4 col-sm-6" v-for="  item   in   products  ">
                                    <!-- product grid start -->
                                    <div class="product-item">
                                        <figure class="product-thumb">
                                            <a href="javascript:;" @click="goto(item.id)">

                                                <img class="pri-img" :src="serverUrl + item.showPics" alt="product">

                                            </a>
                                            <div class="product-badge">
                                                <!-- <div class="product-label new">
                                                <span>new</span>
                                            </div>
                                            <div class="product-label discount">
                                                <span>10%</span>
                                            </div> -->
                                            </div>
                                            <div class="button-group">
                                                <a href="javascript:;" data-toggle="tooltip" data-placement="left"
                                                    title="Add to wishlist" @click="addFavorite(item)"><i
                                                        class="lnr lnr-heart"></i></a>
                                                <!-- <a href="javascript:;" data-toggle="modal" data-target="#quick_view"><span
                                                    data-toggle="tooltip" data-placement="left" title="Quick View"><i
                                                        class="lnr lnr-magnifier"></i></span></a> -->
                                                <a href="javascript:;" data-toggle="tooltip" data-placement="left"
                                                    @click="addCart(item)" title="Add to Cart"><i
                                                        class="lnr lnr-cart"></i></a>
                                            </div>
                                        </figure>
                                        <div class="product-caption">
                                            <p class="product-name">
                                                <a href="javascript:;" @click="goto(item.id)">{{ item.productName }}</a>
                                                <a href="javascript:;" @click="goto(item.id)">{{ item.productCode }}</a>
                                            </p>
                                            <div class="price-box">
                                                <span class="price-regular">${{ item.price }}</span>
                                                <!-- <span class="price-old"><del>$50.00</del></span> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- product grid end -->

                                    <!-- product list item end -->
                                    <div class="product-list-item">
                                        <figure class="product-thumb" @click="goto(item.id)">
                                            <a href="javascript:;">
                                                <img class="pri-img" :src="serverUrl + item.showPics" alt="product">

                                            </a>
                                            <div class="product-badge">
                                                <!-- <div class="product-label new">
                                                <span>new</span>
                                            </div>
                                            <div class="product-label discount">
                                                <span>10%</span>
                                            </div> -->
                                            </div>
                                        </figure>
                                        <div class="product-content-list">
                                            <h5 class="product-name"><a href="javascript:;" @click="goto(item.id)">{{
                                                item.productName }}</a></h5>
                                            <div class="price-box">
                                                <span class="price-regular">${{ item.price }}</span>

                                            </div>
                                            <p>{{ item.intro }}
                                            </p>
                                            <div class="button-group-list">
                                                <a class="btn-big" href="javascript:;" data-toggle="tooltip"
                                                    title="Add to Cart" @click="addCart(item)"><i
                                                        class="lnr lnr-cart"></i>{{ $t("shop.addcart")
                                                        }}</a>

                                                <a href="javascript:;" data-toggle="tooltip" title="Add to wishlist"
                                                    @click="addFavorite(item)"><i class="lnr lnr-heart"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- product list item end -->
                                </div>
                                <!-- product single item start -->











                            </div>
                            <!-- product item list wrapper end -->

                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                                <el-pagination background layout="prev, pager, next" :total="totalRecord"
                                    @current-change="paging" :current-page="currentpage" :page-size="12">
                                </el-pagination>
                            </div>
                            <!-- end pagination area -->
                        </div>
                    </div>
                    <!-- shop main wrapper end -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import top_head from "@/components/top_head.vue"
import * as api from "@/api/api"
import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
import prd_type from "@/components/prd_type.vue"
export default {
    components: {
        top_head,
        Lang,
        foot,
        prd_type
    },
    data() {
        return {
            sellan: "",
            drawer: false,
            banner_height: '510px',
            products: [],
            types: [],
            totalRecord: 0,
            currentpage: 1,
            pagesize: 12,
            form_rec: 1,
            to_rec: 1,
            type: 0,
            serverUrl: this.$root.serverUrl,
            showTitle: localStorage.getItem("showTitlex"),
            sortby: "1",
            range1: 0,
            range2: 9999

        }
    },
    created() {

        this.type = this.$route.params.type
        this.sellan = localStorage.getItem("lang")
        this.get_product(this.type)

    },
    mounted() {
        this.$bus.$on('languageChanged', (language) => {
            this.sellan = language;

        });
        this.$bus.$on('pathTxt', (pathTxt) => {

            this.showTitle = pathTxt

        });


    },
    methods: {

        gotoPage(page) {
            this.$router.push(page);
            // location.reload();
        },
        get_product(val) {
            var prdType = ""
            if (val) {
                prdType = val
            }
            else {
                prdType = this.type
            }
            api.getRequest('/products',
                {
                    keywords: "", prdType: prdType, topage: this.currentpage,
                    pagesize: this.pagesize, sortby: this.sortby, range1: this.range1, range2: this.range2
                }).then(resp => {
                    this.products = resp.data.records
                    this.totalRecord = resp.data.total
                    var sellan = this.sellan
                    this.products.forEach(function (item) {
                        if (sellan == "en") {
                            item.productName = item.productNameEn

                        }
                        if (sellan == "tc") {
                            item.productName = item.productNameTc
                        }
                        if (sellan == "cn") {
                            item.productName = item.productName
                        }
                    })


                    this.form_rec = 1 + this.pagesize * (this.currentpage - 1)

                    var to_rec = this.form_rec + this.pagesize - 1
                    if (to_rec >= this.totalRecord) {
                        to_rec = this.totalRecord
                    }
                    this.to_rec = to_rec
                })
        },
        addCart(item) {
            item.quantity = 1
            this.$shop.addCart(item)
            this.$bus.$emit('cart', 1);
        },
        addFavorite(item) {
            this.$shop.addFavorite(item)
            var i = parseInt(sessionStorage.getItem("favorite"))
            this.$bus.$emit('favorite', i + 1);
        },
        select_type(val) {

            localStorage.setItem("showTitlex", val.title)
            // this.showTitle = val.title
            this.$router.push("/shopping/" + val.id)



            // this.get_product(val)
        },

        switchLang(val) {
            this.setLang(val)
        },

        goto(id) {
            this.$router.push("/productDetail/" + id)
        },
        paging(val) {

            this.currentpage = val;
            this.get_product(this.type);
        },
        selSort(val) {

            this.get_product()

        },
        price_range(val) {

            this.range1 = val[0]
            this.range2 = val[1]
            this.get_product()
        }


    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}



.el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4F4AA7;
    color: #FFF;
}
</style>