<template>
    <div class="main-wrapper">
        <!-- Start Contact Form -->
        <div class="contact-form ptb--130 ptb_md--80 ptb_sm--80 bg-color" data-bg-color="#ffffff">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2">

                    </div>
                    <div class="col-lg-8">
                        <div class="contact-form-wrapper">
                            <div class="section-title mb--30">
                                <h1 class="fontWeight400">{{ $t("contact") }}</h1>

                                <p class="font--18">{{ $t("contacttxt") }}</p>
                            </div>
                            <!-- Start Contact Wrapper -->
                            <form class="contact__form--1" id="contact-form">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <input name="con_name" type="text" placeholder="Name *" v-model="name">
                                    </div>
                                    <div class="col-lg-6 mt--20">
                                        <input name="con_email" type="text" placeholder="Email *" v-model="email">
                                    </div>
                                    <div class="col-lg-6 mt--20">
                                        <input name="con_phone" type="text" placeholder="Phone *" v-model="phone">
                                    </div>

                                    <div class="col-lg-12 mt--20">
                                        <textarea name="con_message" placeholder="Message" v-model="message"></textarea>
                                    </div>
                                    <div class="col-lg-12 mt--30">
                                        <input type="submit" value="Send Message" @click="submit">

                                    </div>
                                </div>
                            </form>
                            <!-- End Contact Wrapper -->
                        </div>
                    </div>
                    <div class="col-lg-2">

                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Form -->

    </div>
</template>
<script>
import * as api from "../api/api"


import Lang from "@/components/lang.vue"

export default {
    components: {

    },
    data() {
        return {
            sellan: "cn",
            section: "story",
            showpic: "images/contact.jpg",
            message: "",
            email: "",
            phone: "",
            name: "",
            subject: ""
        }
    },
    mounted() {


        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }

    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        switchLang(val) {
            this.setLang(val)
        },
        submit() {
            if (!this.name == "" && this.message != "" && this.phone != "" && this.email != "") {

                var form = {
                    subject: this.subject,
                    firstName: this.name,
                    email: this.email,
                    phone: this.phone,
                    message: this.message
                }
                console.log(form)
                api.postRequest('/admin/contact',
                    form).then(resp => {
                        console.log(resp)
                        this.$message.success("留言成功!");
                    })
            }
            else {
                this.$message.info("請輸入全部內容!");
            }

        }
    }
}
</script>