import * as api from "./api"
import { Message } from 'element-ui'
import router from '../router/'
export function addCart(item) {
    // console.log(item)
    if (sessionStorage.getItem("uid")) {
        var form = {
            productId: item.id,
            Price: item.price,
            quantity: item.quantity,
            userToken: sessionStorage.getItem("uid")
        }
        // console.log(form)
        api.postRequest('/cart',
            form).then(resp => {

                if (resp.data == 1) {
                    var i = parseInt(sessionStorage.getItem("cart"))
                    sessionStorage.setItem("cart", i + item.quantity)
                    Message.success("添加購物車成功!");
                }
                else {
                    Message.info("请先登录");
                }

            })
    }
    else {
        Message.info("请先登录");
    }

}

export function addFavorite(item) {
    console.log(item)
    if (sessionStorage.getItem("uid")) {
        var form = {
            sectionId: item.id,
            userToken: sessionStorage.getItem("uid"),
            section: "product"
        }
        console.log(form)
        api.postRequest('/favorite/new',
            form).then(resp => {
                if (resp.data.res == 1) {
                    Message.success("收藏成功!");

                }
                else {
                    Message.info("请先登录");
                }

            })
    }
    else {
        Message.info("请先登录");
    }

}

export function isLogin() {

    if (sessionStorage.getItem("uid")) {

        api.getRequest('/user/isLogin',
            {}).then(resp => {
                if (resp.data.isLogin == 1) {
                    console.log("登录状态!");
                }
                else {
                    Message.info("请先登录");
                    router.push('/login')

                }

            })
    }
    else {
        Message.info("请先登录");
        router.push('/login')
    }

}


export function loadSetting() {


    api.getRequest('/setting',
        { ids: "1,2,3,4,5,6,7,8" }).then(resp => {
            console.log(resp.data.data)

            localStorage.setItem("c_add", resp.data.data[0].settingVal)
            localStorage.setItem("e_add", resp.data.data[1].settingVal)
            localStorage.setItem("tel", resp.data.data[2].settingVal)
            localStorage.setItem("email", resp.data.data[3].settingVal)
            localStorage.setItem("facebook", resp.data.data[5].settingVal)
            localStorage.setItem("whatapp", resp.data.data[4].settingVal)
            localStorage.setItem("inst", resp.data.data[6].settingVal)
            localStorage.setItem("siteName", resp.data.data[7].settingVal)



        })


}


export function loadFavorite() {

    if (sessionStorage.getItem("uid")) {

        api.getRequest('/favorite/count',
            { token: sessionStorage.getItem("uid"), }).then(resp => {
                console.log(resp)

                sessionStorage.setItem("favorite", resp.data)
                console.log("收藏!" + resp.data);

            })
    }

}

export function loadCart() {

    if (sessionStorage.getItem("uid")) {

        api.getRequest('/myCart/count',
            { userToken: sessionStorage.getItem("uid"), }).then(resp => {
                console.log(resp)

                sessionStorage.setItem("cart", resp.data.res)
                console.log("购物车!" + resp.data.res);

            })
    }

}

export default {
    addCart,
    addFavorite,
    isLogin,
    loadSetting,
    loadFavorite,
    loadCart
}