<template>
    <section class="banner-statistics-right">
        <div class="container">
            <div class="row">
                <!-- start banner item start -->
                <div class="col-md-6" v-for="item in banners">
                    <div class="banner-item banner-border">
                        <figure class="banner-thumb">
                            <a href="javascript:;" @click="goto(item)">
                                <img :src="item.image" alt="">
                            </a>
                            <figcaption class="banner-content banner-content-right">
                                <h2 class="text1">{{ item.text2 }}</h2>
                                <h2 class="text2">{{ item.text1 }}</h2>
                                <a class="store-link" href="javascript:;" @click="goto(item)">buy it now</a>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <!-- start banner item end -->
            </div>
        </div>
    </section>
</template>
<script>
import * as api from "@/api/api"
export default {
    data() {
        return {
            clientName: this.$root.clientName,
            clientLink: this.$root.clientLink,
            sellan: "cn",
            banner_height: '510px',
            banners: []
        }
    },
    created() {
        // this.get_banner(5)
    },
    props: {
        'lang': {
            type: String,
            Default: 'tc'
        },

    },
    watch: {
        'lang': {
            immediate: true,
            handler(newVal) {
                this.get_banner(4)
            },
            deep: true
        },
    },
    computed: {

    },

    methods: {
        get_banner(val) {
            api.getRequest('/banners',
                { position: val }).then(resp => {

                    var sellan = this.lang//localStorage.getItem("lang")
                    this.banners = resp.data

                    var banners = this.banners
                    banners.forEach(function (item) {
                        if (sellan == "en") {
                            item.text1 = item.text1En
                            item.text2 = item.text2En
                        }
                        if (sellan == "tc") {
                            item.text1 = item.text1Tc
                            item.text2 = item.text2Tc
                        }
                        if (sellan == "cn") {
                            item.text1 = item.text1
                            item.text2 = item.text2
                        }
                    })
                    this.banners = banners
                    // console.log(this.banners)
                })
        },
        goto(item) {
            if (item.type != "function") {
                this.$router.push("/" + item.type + "/" + item.url)
            }

        }


    }
}
</script>