<template>
    <section class="slick-slider">
        <el-carousel :interval="5000" arrow="always" :height="banner_height">
            <el-carousel-item v-for="item in banners">
                <div class="hero-slider-item"
                    :style="'background-image: url(' + item.image + ');background-size:cover;background-position: center;'">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="hero-slider-content slide-2">
                                    <span>{{ item.text1 }}</span>
                                    <div v-html="item.text2"></div>
                                    <a href="javascript:;" class="btn-hero" @click="goto(item)">{{ $t('index.more') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </el-carousel-item>
        </el-carousel>
    </section>
</template>
<script>
import * as api from "@/api/api"
export default {
    data() {
        return {
            clientName: this.$root.clientName,
            clientLink: this.$root.clientLink,
            sellan: "cn",
            banner_height: '510px',
            banners: []
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        // this.get_banner(5)


    },
    props: {
        'lang': {
            type: String,
            Default: 'tc'
        },

    },
    watch: {
        'lang': {
            immediate: true,
            handler(newVal) {
                this.get_banner(5)
            },
            deep: true
        },
    },
    computed: {

    },

    methods: {
        get_banner(val) {
            api.getRequest('/banners',
                { position: val }).then(resp => {

                    var sellan = this.lang//localStorage.getItem("lang")
                    this.banners = resp.data

                    var banners = this.banners
                    banners.forEach(function (item) {
                        if (sellan == "en") {
                            item.text1 = item.text1En
                            item.text2 = item.text2En
                        }
                        if (sellan == "tc") {
                            item.text1 = item.text1Tc
                            item.text2 = item.text2Tc
                        }
                        if (sellan == "cn") {
                            item.text1 = item.text1
                            item.text2 = item.text2
                        }
                    })
                    this.banners = banners
                    // console.log(this.banners)
                })
        },
        handleResize() {
            // console.log(window.innerWidth)
            if (window.innerWidth <= 767) {
                this.banner_height = '310px'
            } else {
                this.banner_height = '510px'
            }
        },
        goto(item) {
            if (item.type != "function") {
                this.$router.push("/" + item.type + "/" + item.url)
            }

        }

    }
}
</script>