<template>
    <!-- Start Footer Area Wrapper -->
    <footer class="footer-wrapper">

        <!-- footer widget area start -->
        <div class="footer-widget-area">
            <div class="container">
                <div class="footer-widget-inner section-space">
                    <div class="row mbn-30">
                        <!-- footer widget item start -->
                        <div class="col-lg-5 col-md-6 col-sm-8">
                            <div class="footer-widget-item mb-30">
                                <div class="footer-widget-title">
                                    <h5>My account</h5>
                                </div>
                                <ul class="footer-widget-body accout-widget">
                                    <li class="address">
                                        <em><i class="lnr lnr-map-marker"></i></em>
                                        {{ add }}
                                    </li>
                                    <li class="email">
                                        <em><i class="lnr lnr-envelope"></i>Mail us: </em>
                                        <a :href="'mailto:' + email">{{ email }}</a>
                                    </li>
                                    <li class="phone">
                                        <em><i class="lnr lnr-phone-handset"></i> Phones: </em>
                                        <a :href="'tel:' + tel">{{ tel }}</a>
                                    </li>
                                </ul>
                                <div class="payment-method">
                                    <img src="assets/img/payment-pic.png" alt="payment method">
                                </div>
                            </div>
                        </div>
                        <!-- footer widget item end -->

                        <!-- footer widget item start -->
                        <div class="col-lg-2 col-md-6 col-sm-4" v-for="item in columns">
                            <div class="footer-widget-item mb-30">
                                <div class="footer-widget-title">
                                    <h5>{{ item.title }}</h5>
                                </div>
                                <ul class="footer-widget-body">
                                    <li v-for="i in item.children"><a href="javascript:;" @click="goto(i)">{{ i.title }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- footer widget item end -->


                    </div>
                </div>
            </div>
        </div>
        <!-- footer widget area end -->

        <!-- footer bottom area start -->
        <div class="footer-bottom-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-2 order-md-1">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2017-{{ currentYear }}. {{ clientName }} All rights reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-6 order-1 order-md-2">
                        <div class="footer-social-link">
                            <a :href="whatapp" target="_blank"><i class="fa fa-whatsapp"></i></a>
                            <a :href="twitter" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a :href="facebook" target="_blank"><i class="fa fa-facebook"></i></a>
                            <a :href="inst" target="_blank"><i class="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer bottom area end -->
        <!-- Scroll to top start -->
        <div class="scroll-top not-visible">
            <i class="fa fa-angle-up"></i>
        </div>
        <div id="fb-customer-chat" class="fb-customerchat">
        </div>
        <!-- Scroll to Top End -->
    </footer>
    <!-- End Footer Area Wrapper -->
</template>
<script>
import * as api from "../api/api"
export default {
    created() {
        if (localStorage.getItem("lang") == "en") {
            this.add = localStorage.getItem('e_add')

        } else {
            this.add = localStorage.getItem('c_add')
        }
        this.get_data()
    },
    data() {
        return {
            clientName: localStorage.getItem('siteName'),
            clientLink: this.$root.clientLink,
            email: localStorage.getItem('email'),
            tel: localStorage.getItem('tel'),
            add: localStorage.getItem('c_add'),
            facebook: localStorage.getItem('facebook'),
            twitter: localStorage.getItem('twitter'),
            inst: localStorage.getItem('inst'),
            whatapp: localStorage.getItem('whatapp'),
            columns: []

        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        get_data() {

            var params = {
                web: 3
            }
            var lang = localStorage.getItem("lang")
            api.getRequest('/columns/all',
                params).then(resp => {
                    // console.log(resp)
                    this.columns = resp.data[0].children
                    this.columns.forEach(function (item) {
                        if (lang == "en") {
                            item.title = item.titleEn
                        }
                        if (lang == "tc") {
                            item.title = item.titleTc
                        }
                        item.children.forEach(function (i) {
                            if (lang == "en") {
                                i.title = i.titleEn
                            }
                            if (lang == "tc") {
                                i.title = i.titleTc
                            }
                        })
                    })
                })
        },
        goto(item) {
            if (item.section != "function") {
                this.$router.push("/" + item.section + "/" + item.link)
            }
            if (item.section == "function") {
                this.$router.push("/" + item.link)
            }
        }
    }
}
</script>