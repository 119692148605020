<template>
    <div class="main-wrapper">

        <!-- Checkout Page Start -->
        <div class="checkout_area pt--120 pt_md--80 pt_sm--80 pb--60 bg-color" data-bg-color="#ffffff">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Checkout Form s-->
                        <form class="checkout-form">
                            <div class="row">
                                <div class="col-lg-7 mb--20">
                                    <!-- Billing Address -->
                                    <div id="billing-form" class="mb--40">
                                        <h4 class="checkout-title">Billing Address</h4>

                                        <div class="row">

                                            <div class="col-12 mb--20">
                                                <label>選擇地址*</label>
                                                <address_select></address_select>
                                            </div>
                                            <div class="col-md-6 col-12 mb--20">
                                                <label>收貨人*</label>
                                                <input type="text" placeholder="收貨人姓名" v-model="form.consignee">
                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>Email*</label>
                                                <input type="email" placeholder="Email Address" v-model="form.email">
                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>手機號碼*</label>
                                                <input type="text" placeholder="Phone number" v-model="form.mobile">
                                            </div>


                                            <div class="col-12 mb--20">
                                                <label>詳細地址*</label>
                                                <input type="text" placeholder="Address line 1" v-model="form.address">

                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>Country*</label>

                                                <input type="text" placeholder="Country" v-model="form.country">
                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>Town/City*</label>
                                                <input type="text" placeholder="Town/City" v-model="form.city">
                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>State*</label>
                                                <input type="text" placeholder="State" v-model="form.province">
                                            </div>

                                            <div class="col-md-6 col-12 mb--20">
                                                <label>Zip Code*</label>
                                                <input type="text" placeholder="Zip Code" v-model="form.zipCode">
                                            </div>

                                        </div>

                                    </div>



                                </div>

                                <div class="col-lg-5">
                                    <div class="row">

                                        <!-- Cart Total -->
                                        <div class="col-12 mb--60">

                                            <h4 class="checkout-title">Cart Total</h4>

                                            <div class="checkout-cart-total">

                                                <h4>Product <span>Total</span></h4>

                                                <ul>
                                                    <li v-for="item in cart">{{ item.prdName }} X {{ item.quantity }}
                                                        <span>${{ item.price * item.quantity }}</span>
                                                    </li>

                                                </ul>

                                                <p>Sub Total <span>${{ checkout.total }}</span></p>
                                                <p>Shipping Fee <span>${{ checkout.fee }}</span></p>

                                                <h4>Grand Total <span>${{ checkout.grand }}</span></h4>

                                            </div>

                                        </div>

                                        <!-- Payment Method -->
                                        <div class="col-12 mb--60">

                                            <h4 class="checkout-title">Payment Method</h4>

                                            <div class="checkout-payment-method">

                                                <div class="single-method" v-for="item, index in pays">
                                                    <input type="radio" :name="item.value" :id="index" @change="pay_sel"
                                                        :value="item.value" v-model="pay_met">
                                                    <label :for="index">{{ item.label }}</label>

                                                </div>



                                            </div>

                                            <button class="place-order" type="button" @click="submit_order">Place
                                                order</button>
                                            <!-- <PayPal amount="10.00" currency="USD" :client="credentials"
                                                :env="credentials.production" @payment-authorized="paymentAuthorized">
                                            </PayPal> -->
                                            <div id="paypal-button"></div> <!-- PayPal付款按钮的容器元素 -->
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
        <!-- Checkout Page End -->

    </div>
</template>

<script>

// import PayPal from 'vue-paypal-checkout'
import PayPal from 'paypal-checkout'
import * as api from "../api/api"
import address_select from "@/components/address_select.vue"
export default {
    data() {
        return {
            sellan: "tc",
            // lang: 0,
            drawer: false,
            columns: [],

            checkout: {},
            cart: [],
            total: 0,
            fee: 0,
            grand: 0,
            form: {},
            options: [{
                value: '选项1',
                label: '香港'
            },],
            pay_met: "",
            pays: [{
                value: 'Paypal',
                label: 'Paypal'
            }, {
                label: '貨到付款',
                value: 'cashondelivery'
            }, {
                label: 'MasterCard',
                value: 'MasterCard'
            },],
            credentials: {
                sandbox: 'AT4R0x3ypQIjYjlSpBtgGTmfYU5NTmMHPAVR4_3WliiR204UmjrSbO6LGJj5HBSTOmmHk1eTNIHetSGz', //上方说明保存的client Id 测试
                production: 'AT4R0x3ypQIjYjlSpBtgGTmfYU5NTmMHPAVR4_3WliiR204UmjrSbO6LGJj5HBSTOmmHk1eTNIHetSGz' // client ID 线上 真实
            }

        }
    },
    mounted() {

        PayPal.Button.render({
            env: 'sandbox', // 设置环境为沙箱环境，用于测试  
            client: {
                sandbox: 'YOUR_SANDBOX_CLIENT_ID', // 在PayPal开发者控制台中创建应用程序时分配的客户端ID  
                production: 'AT4R0x3ypQIjYjlSpBtgGTmfYU5NTmMHPAVR4_3WliiR204UmjrSbO6LGJj5HBSTOmmHk1eTNIHetSGz' // 生产环境中的客户端ID  
            },
            commit: true, // 在页面上显示“付款”按钮  
            payment: function (data, actions) {
                return actions.payment.create({
                    payment: {
                        transactions: [
                            {
                                amount: { total: '1.00', currency: 'USD' } // 设置交易金额和货币类型  
                            }
                        ]
                    }
                });
            },
            onAuthorize: function (data, actions) {
                return actions.payment.execute().then(function () {
                    // 处理付款成功的逻辑，例如显示成功消息或将用户重定向到另一个页面  
                });
            }
        }, '#paypal-button'); // 指定付款按钮在页面上的容器元素ID  

    },
    created() {


        this.$shop.isLogin()
        this.checkout = api.return_jason(localStorage.getItem("cart"))

        this.cart = this.checkout.cart
        this.$bus.$on('sel_add', (sel_add) => {

            this.form = sel_add;
            console.log(this.form)

        });
    },
    components: {
        address_select, PayPal
    },
    methods: {
        paymentAuthorized(data) {
            // 授权完成的回调，可以拿到订单id
            console.log(data);
        },
        pay_sel() {
            console.log(this.pay_met)
        },
        submit_order() {
            var address = this.form
            console.log(this.form)
            if (address.consignee != "" && address.email != "" && address.mobile != "" && address.address != "" && address.country != "" && address.city != "" && address.province != "" && address.zipCode != "") {

                if (this.pay_met == "") {
                    this.$message.info("請選擇支付方式");
                }
                else {
                    var address = this.form
                    address.addType = 2
                    address.id = ""
                    var order = {
                        userToken: sessionStorage.getItem("uid"),
                        purchase: this.form.consignee,
                        payMethod: this.pay_met,
                        amount: this.checkout.total,
                        amountFinal: this.checkout.grand,
                        deliveryFee: this.checkout.fee,
                        addressId: this.form.id,
                        orderStatus: 0,
                        items: this.cart,
                        address: address
                    }
                    console.log(order)
                    api.postRequest('/order', order).then(resp => {

                        if (resp.data.rs != -1) {
                            if (resp.data.res == 1) {
                                this.$message.success("訂單提交成功!");
                                this.$router.push("/myAccount")

                            }

                        } else {
                            this.$message.info("请先登录");
                            this.$router.push("/login")
                        }
                    })
                }
            }
            else {
                this.$message.info("請輸入地址,或者在地址簿選擇地址");
            }

        },
        async pay_with_paypal() {
            try {
                paypal = await loadScript({
                    clientId: this.clientId.sandbox,//在开发者后台获取
                    currency: "XXX"//货币单位，默认USD，需要和购物车的货币单位保持一致
                });
            } catch (error) {
                console.log(error, "error with paypal");
            }
            if (paypal) {
                try {
                    //Buttons系统默认只有paypal和信用卡、借记卡支付两种
                    await paypal.Buttons({
                        async createOrder() {
                            //调起创建支付订单的接口
                        },
                        async onApprove(data) {
                            //付款接口
                        },
                        onCancel(data) {
                            console.log('取消');
                        }
                    })
                        .render("#paypal-container");
                } catch (error) {
                    console.log(error, "paypal button");
                }
            }
        },




    }
}
</script>