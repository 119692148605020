<template>
    <div>
        <top_head></top_head>

        <!-- off-canvas menu start -->
        <aside class="off-canvas-wrapper">
            <div class="off-canvas-overlay"></div>
            <div class="off-canvas-inner-content">
                <div class="btn-close-off-canvas">
                    <i class="lnr lnr-cross"></i>
                </div>

                <div class="off-canvas-inner">
                    <!-- search box start -->
                    <div class="search-box-offcanvas">
                        <form>
                            <input type="text" placeholder="Search Here...">
                            <button class="search-btn"><i class="lnr lnr-magnifier"></i></button>
                        </form>
                    </div>
                    <!-- search box end -->

                    <!-- mobile menu start -->
                    <div class="mobile-navigation">

                        <!-- mobile menu navigation start -->
                        <nav>
                            <ul class="mobile-menu">
                                <li><a href="index.html">Home</a></li>
                                <li><a href="shop.html">Shop</a></li>
                                <li><a href="product-details.html">Product Details</a></li>
                            </ul>
                        </nav>
                        <!-- mobile menu navigation end -->
                    </div>
                    <!-- mobile menu end -->

                    <div class="mobile-settings">
                        <ul class="nav">
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="currency" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Currency
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="currency">
                                        <a class="dropdown-item" href="#">$ USD</a>
                                        <a class="dropdown-item" href="#">$ EURO</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="myaccount" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        My Account
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="myaccount">
                                        <a class="dropdown-item" href="#">my account</a>
                                        <a class="dropdown-item" href="#"> login</a>
                                        <a class="dropdown-item" href="#">register</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- offcanvas widget area start -->
                    <div class="offcanvas-widget-area">
                        <div class="off-canvas-contact-widget">
                            <ul>
                                <li><i class="fa fa-mobile"></i>
                                    <a href="#">0123456789</a>
                                </li>
                                <li><i class="fa fa-envelope-o"></i>
                                    <a href="#">info@yourdomain.com</a>
                                </li>
                            </ul>
                        </div>
                        <div class="off-canvas-social-widget">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-pinterest-p"></i></a>
                            <a href="#"><i class="fa fa-linkedin"></i></a>
                            <a href="#"><i class="fa fa-youtube-play"></i></a>
                        </div>
                    </div>
                    <!-- offcanvas widget area end -->
                </div>
            </div>
        </aside>
        <!-- off-canvas menu end -->

        <!-- main wrapper start -->
        <main>
            <div class="goods-image">
                <!-- 预览大图 -->
                <div class="large" v-show="show" :style="[{ backgroundImage: `url(${images[currIndex]})` }, bgPosition]">
                </div>
                <!-- 商品大图 -->
                <div class="middle">
                    <!-- 商品大图 -->
                    <img ref="target" :src="images[currIndex]" alt="" style="width: 499px;height: 499px;" />
                    <!-- 鼠标进入图片的遮罩 -->
                    <div class="layer" v-show="show" :style="[position]"></div>
                </div>


            </div>
            <!-- 缩略小图 -->
            <div style="position: relative; width: 500px; height: 110px; overflow: hidden;">
                <div style="position: absolute; width: 1200px;">
                    <div :class="i == currIndex ? 'list-pic act' : 'list-pic'" v-for=" (img, i) in images" :key="i">
                        <img @mouseenter="currIndex = i" :src="img" alt="" />
                    </div>
                </div>
            </div>
            <!-- <div style="width: 100%;height: 100px; overflow: scroll;display: flex;">
                <div class="list-pic" v-for=" (img, i) in images" :key="img"> <img @mouseenter="currIndex = i" :src="img"
                        alt="" /></div> -->
            <!-- <ul class="small-pic">
                    <li v-for="(img, i) in images" :key="img" :class="{ active: i === currIndex }">-->
            <!-- 鼠标移入商品大图旁边的小图商品大图位置就会显示该图 -->
            <!-- <img @mouseenter="currIndex = i" :src="img" alt="" /> -->
            <!-- </li>
                </ul>  -->


            <foot></foot>
        </main>
        <!-- main wrapper end -->

    </div>
</template>
<script>
import top_head from "@/components/top_head.vue"

import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
import { reactive, ref, watch } from 'vue'
import { useMouseInElement } from '@vueuse/core'
export default {
    components: {
        top_head,
        Lang,
        foot
    },
    data() {
        return {
            sellan: "cn",
            drawer: false,
            banner_height: '510px',

        }
    },
    props: {
        images: {
            type: Array,
            default: () => ["https://apec.microbee.cn/upload_file/20230907/abd7b685e14f4de1987e403aed879ae7.jpg", "https://apec.microbee.cn/upload_file/20230907/eece292b05c246efa991ccd8d066756f.jpg", "https://img10.360buyimg.com/imgzone/jfs/t1/169039/5/15753/41768/60642bb6E14159739/4bd9fd5733d5101e.jpg", "https://img10.360buyimg.com/imgzone/jfs/t1/169039/5/15753/41768/60642bb6E14159739/4bd9fd5733d5101e.jpg", "https://img10.360buyimg.com/imgzone/jfs/t1/169039/5/15753/41768/60642bb6E14159739/4bd9fd5733d5101e.jpg", "https://img10.360buyimg.com/imgzone/jfs/t1/169039/5/15753/41768/60642bb6E14159739/4bd9fd5733d5101e.jpg", "https://img10.360buyimg.com/imgzone/jfs/t1/169039/5/15753/41768/60642bb6E14159739/4bd9fd5733d5101e.jpg"]
        }
    },
    setup(props) {
        // 控制预览大图与遮罩层的显示与隐藏
        const show = ref(false)
        // 控制商品大图显示那张图片
        const currIndex = ref(0)
        // ref 获取 DOM 元素
        const target = ref(null)
        // 记录遮罩半透明图在商品大图中的坐标位置
        const position = reactive({
            top: 0,
            left: 0
        })
        // 记录遮罩层做覆盖的区域在预览时的预览大图的坐标位置
        const bgPosition = reactive({
            backgroundPositionX: 0,
            backgroundPositionY: 0
        })
        // 第三方 vueuse 提供的方法 useMouseInElement 获取鼠标在某块区域的坐标
        const { elementX, elementY, isOutside } = useMouseInElement(target)
        // 侦听器监测鼠标进入商品大图时的坐标从而操纵遮罩层和预览大图的效果
        watch([elementX, elementY, isOutside], () => {
            // isisOutside.value 为 true 的时候代表鼠标未进入目标元素，为 false 时代表鼠标进入目标元素
            // 为 true 是不记录坐标以免损耗性能
            if (isOutside.value) {
                // 鼠标未进入目标元素不显示遮罩层和预览大图
                show.value = false
                return
            }
            // 鼠标进入目标元素显示遮罩层和预览大图
            show.value = true
            // 判断边界值，根据遮罩层大小、商品图片大小判断
            // left 的边界值（左、右）
            if (elementX.value < 100) {
                position.left = 0
            } else if (elementX.value > 300) {
                position.left = 200
            } else {
                position.left = elementX.value - 100
            }
            // top 的边界值（上、下）
            if (elementY.value < 100) {
                position.top = 0
            } else if (elementY.value > 300) {
                position.top = 200
            } else {
                position.top = elementY.value - 100
            }
            // 遮罩层所覆盖的商品图片部分在预览大图中的坐标，加单位
            bgPosition.backgroundPositionY = -position.top * 2 + 'px'
            bgPosition.backgroundPositionX = -position.left * 2 + 'px'
            // 遮罩层相对于商品大图左上角坐标，加单位
            position.top += 'px'
            position.left += 'px'
        })
        // 返回给模板使用
        return { currIndex, show, target, position, bgPosition }
    },
    mounted() {

        // localStorage.setItem("lang", "cn")
        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "cn")
        }
        window.addEventListener('resize', this.handleResize)


    },
    methods: {
        setLang(val) {
            console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        goto(page) {
            this.$router.push(page);
            // location.reload();
        },
        switchLang(val) {
            this.setLang(val)
        },
        handleResize() {
            console.log(window.innerWidth)
            if (window.innerWidth <= 767) {
                this.banner_height = '310px'
            } else {
                this.banner_height = '510px'
            }
        },

    }
}
</script>
<style  >
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.goods-image {
    width: 502px;
    height: 540px;
    position: relative;
    display: flex;

}

.large {
    position: absolute;
    top: 0;
    left: 512px;
    width: 400px;
    height: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    background-color: #f8f8f8;
}

.middle {
    width: 502px;
    height: 502px;
    position: relative;
    cursor: move;
    border: 1px solid #dfdede;

    .layer {
        width: 200px;
        height: 200px;
        background: rgba(0, 0, 0, 0.2);
        left: 0;
        top: 0;
        position: absolute;
    }
}

/* .small-pic {
    width: 80px;

    li {
        width: 68px;
        height: 68px;
        margin-left: 12px;
        margin-bottom: 15px;
        cursor: pointer;
        border: 2px solid #f8f8f8;

        &:hover,
        &.active {
            border: 2px solid #f8f8f8;
        }
    }

} */

.small-pic {

    display: flex;
    gap: 20px;


}


.small-pic li {
    width: 100px;
    height: 100px;
    margin-left: 12px;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid #dfdede;
}

.list-pic {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #dfdede;
}

.list-pic:hover {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-right: 20px;
    border: 1px solid #4F4AA7;
}

.act {
    border: 1px solid #4F4AA7;
}

.el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
}
</style>