<template>
    <div class="main-wrapper">
        <!-- breadcrumb area start -->
        <div class="breadcrumb-area common-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-wrap">
                            <nav aria-label="breadcrumb">
                                <h1>My Favorite</h1>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                    <li class="breadcrumb-item active" aria-current="page">我的收藏</li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- breadcrumb area end -->
        <!-- Cart Page Start -->
        <div class="cart_area pt--120 pb--80 bg-color pt_md--80 pt_sm--80" data-bg-color="#ffffff">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <!-- Cart Table -->
                        <div class="cart-table table-responsive mb--40">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="pro-thumbnail">商品图片</th>
                                        <th class="pro-title">商品名称</th>
                                        <th class="pro-price">价格</th>
                                        <th class="pro-remove"></th>
                                        <th class="pro-remove"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item, index in  myCart " :key="index">
                                        <td class="pro-thumbnail"><a :href="'#/productDetail/' + item.id"><img
                                                    :src="item.showPics" alt="Product"></a>
                                        </td>
                                        <td class="pro-title"><a :href="'#/productDetail/' + item.id"
                                                v-if="sellan == 'cn'">{{
                                                    item.productName }}</a>
                                            <a :href="'#/productDetail/' + item.id" v-if="sellan == 'tc'">{{
                                                item.productNameTc
                                            }}</a>
                                            <a :href="'#/productDetail/' + item.id" v-if="sellan == 'en'">{{
                                                item.productNameEn
                                            }}</a>
                                        </td>
                                        <td class="pro-price"><span>${{ item.price }}</span></td>
                                        <td class="pro-remove"><a href="javascript:;" @click="addCart(item)"><i
                                                    class="lnr lnr-cart"></i></a></td>
                                        <!-- <td class="pro-remove"><a href="javascript:;" @click="addFavorite(item)"><i
                                                    class="fa fa-trash-o"></i></a></td> -->
                                    </tr>

                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>
            </div>
        </div>
        <!-- Cart Page End -->
    </div>
</template>
<script>
import * as api from "../api/api"
export default {
    data() {
        return {
            sellan: "tc",
            // lang: 0,
            drawer: false,
            columns: [],
            langTitle: [
                {
                    code: "en",
                    title: 'English',
                },
                {
                    code: "tc",
                    title: '繁体中文',
                }
            ],
            langSet: "繁体中文",
            openItem: -1,

            activeIndex: '1',
            myCart: [],
            total: 0,
            fee: 0,
            grand: 0,
            options: [{
                value: '选项1',
                label: '香港'
            },],
            section: this.$root.section,
            value: '',
            selectSection: ""
        }
    },
    created() {
        this.$shop.isLogin()
        this.my_cart()
        this.sellan = localStorage.getItem('lang')
        console.log(this.sellan)


    },
    mounted() {
        // this.my_cart()
    },
    methods: {


        my_cart() {
            if (sessionStorage.getItem("uid")) {
                api.getRequest('/favorite/my',
                    { userToken: sessionStorage.getItem("uid") }).then(resp => {
                        console.log(resp)
                        if (resp.data.rs != -1) {
                            this.myCart = resp.data.res

                        } else {
                            this.$message.info("请先登录");
                        }



                    })
            } else {
                this.$message.info("请先登录");
            }
        },
        addCart(item) {
            item.quantity = 1
            this.$shop.addCart(item)
        },
        addFavorite(item) {
            this.$shop.addFavorite(item)

        },
        delete_cart(id) {

            this.$confirm('删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/cart/' + id,
                    {}).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                        this.my_cart()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },





    }
}
</script>