<template>
    <div>
        <top_head @switchLang="switchLang"></top_head>



        <!-- main wrapper start -->
        <main>
            <!-- slider area start -->

            <index_banner :lang="sellan"></index_banner>
            <!-- slider area end -->

            <!-- banner statistics start -->
            <index_mid_banner :lang="sellan"></index_mid_banner>
            <!-- banner statistics end -->

            <!-- service policy start -->
            <section class="service-policy-area section-space p-0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <!-- start policy single item -->
                            <div class="service-policy-item">
                                <div class="icons">
                                    <img src="assets/img/icon/free_shipping.png" alt="">
                                </div>
                                <div class="policy-terms">
                                    <h5>free shipping</h5>
                                    <p>Free shipping all order</p>
                                </div>
                            </div>
                            <!-- end policy single item -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <!-- start policy single item -->
                            <div class="service-policy-item">
                                <div class="icons">
                                    <img src="assets/img/icon/support247.png" alt="">
                                </div>
                                <div class="policy-terms">
                                    <h5>SUPPORT 24/7</h5>
                                    <p>Support 24 hours a day</p>
                                </div>
                            </div>
                            <!-- end policy single item -->
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <!-- start policy single item -->
                            <div class="service-policy-item">
                                <div class="icons">
                                    <img src="assets/img/icon/promotions.png" alt="">
                                </div>
                                <div class="policy-terms">
                                    <h5>ORDER DISCOUNT</h5>
                                    <p>On every order over HK$ 500</p>
                                </div>
                            </div>
                            <!-- end policy single item -->
                        </div>
                    </div>
                </div>
            </section>
            <!-- service policy end -->

            <!-- our product area start -->
            <section class="our-product section-space">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title text-center">
                                <h2>New Products</h2>

                            </div>
                        </div>
                    </div>
                    <div class="row mtn-40">
                        <!-- product single item start -->
                        <div class="col-lg-3 col-md-4 col-sm-6" v-for="item in products">
                            <div class="product-item mt-40">
                                <figure class="product-thumb">
                                    <a href="javascript:;" @click="goto(item.id)">
                                        <img class="pri-img" :src="serverUrl + item.showPics" alt="product">
                                    </a>
                                    <div class="product-badge">
                                        <div class="product-label new">
                                            <span>new</span>
                                        </div>
                                        <!-- <div class="product-label discount">
                                            <span>10%</span>
                                        </div> -->
                                    </div>
                                    <div class="button-group">
                                        <a href="#" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i
                                                class="lnr lnr-heart"></i></a>

                                        <a @click="addCart(item)" data-toggle="tooltip" data-placement="left"
                                            title="Add to Cart"><i class="lnr lnr-cart"></i></a>
                                    </div>
                                </figure>
                                <div class="product-caption">
                                    <p class="product-name">
                                        <a href="javascript:;" @click="goto(item.id)">{{
                                            item.productName }}</a>
                                        <a href="javascript:;" @click="goto(item.id)">{{
                                            item.productCode }}</a>
                                    </p>
                                    <div class="price-box">
                                        <span class="price-regular">${{ item.price }}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- product single item end -->



                        <!-- 
                        <div class="col-12">
                            <div class="view-more-btn">
                                <a class="btn-hero btn-load-more" href="shop.html">view more products</a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </section>
            <!-- our product area end -->

            <!-- banner statistics start -->
            <index_bot_banner :lang="sellan"></index_bot_banner>
            <!-- banner statistics end -->
            <foot></foot>

            <!-- offcanvas search form start -->
            <div>
                <div class="offcanvas-search-inner">
                    <div class="offcanvas-close">
                        <i class="lnr lnr-cross"></i>
                    </div>
                    <div class="container">
                        <div class="offcanvas-search-box">
                            <form class="d-flex bdr-bottom w-100">
                                <input type="text" placeholder="Search entire storage here...">
                                <button class="search-btn"><i class="lnr lnr-magnifier"></i>search</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- offcanvas search form end -->




        </main>
        <!-- main wrapper end -->

    </div>
</template>
<script>
import top_head from "@/components/top_head.vue"
import index_banner from "@/components/index_banner.vue"
import index_mid_banner from "@/components/index_mid_banner.vue"
import index_bot_banner from "@/components/index_bot_banner.vue"
import * as api from "@/api/api"
import Lang from "@/components/lang.vue"
import foot from "@/components/foot.vue"
export default {
    components: {
        top_head,
        Lang,
        foot, index_banner, index_mid_banner, index_bot_banner
    },
    data() {
        return {
            sellan: "tc",
            drawer: false,
            banner_height: '510px',
            banners: [],
            products: [],
            serverUrl: this.$root.serverUrl,
        }
    },
    created() {

        console.log('index' + localStorage.getItem("lang"))
        if (localStorage.getItem("lang")) {
            this.sellan = localStorage.getItem("lang")
            this.setLang(this.sellan)

        } else {
            localStorage.setItem("lang", "tc")
            this.setLang(this.sellan)
        }
        window.addEventListener('resize', this.handleResize)
        this.get_product()
        this.$shop.loadSetting()



    },
    mounted() {
        this.actvie()
    },
    methods: {
        setLang(val) {
            // console.log(val)
            this.sellan = val
            this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
            localStorage.setItem("lang", val)
            document.title = this.$t('index.title')// "abcd"
        },
        goto(page) {
            this.$router.push(page);
            // location.reload();
        },
        switchLang(val) {
            this.setLang(val)
        },
        handleResize() {

            if (window.innerWidth <= 767) {
                this.banner_height = '310px'
            } else {
                this.banner_height = '510px'
            }
        },

        addCart(item) {
            item.quantity = 1
            this.$shop.addCart(item)
        },
        goto(id) {
            this.$router.push("/productDetail/" + id)
        },
        get_product() {

            api.getRequest('/products',
                {
                    keywords: "", topage: 1,
                    pagesize: 8
                }).then(resp => {
                    this.products = resp.data.records
                    this.totalRecord = resp.data.total
                    var sellan = this.sellan
                    console.log(resp)
                    this.products.forEach(function (item) {
                        if (sellan == "en") {
                            item.productName = item.productNameEn

                        }
                        if (sellan == "tc") {
                            item.productName = item.productNameTc
                        }
                        if (sellan == "cn") {
                            item.productName = item.productName
                        }
                    })



                })
        },
        actvie() {
            /*------ Sticky menu start ------*/
            var $window = $(window);
            $window.on('scroll', function () {
                var scroll = $window.scrollTop();
                if (scroll < 300) {
                    $(".sticky").removeClass("is-sticky");
                } else {
                    $(".sticky").addClass("is-sticky");
                }
            });
            /*------ Sticky menu end ------*/













            /*------- offcanvas search form active start -------*/
            $(".offcanvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".offcanvas-search-inner").addClass('show')
            })

            $(".minicart-btn").on('click', function () {
                $("body").addClass('fix');
                $(".minicart-inner").addClass('show')
            })

            $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".offcanvas-search-inner, .minicart-inner").removeClass('show')
            })
            /*------- offcanvas search form active end -------*/


            /*------- nice select active start -------*/
            $('select').niceSelect();
            /*------- nice select active end -------*/

            /*-------- Off Canvas Open close start--------*/
            $(".off-canvas-btn").on('click', function () {
                $("body").addClass('fix');
                $(".off-canvas-wrapper").addClass('open');
            });

            $(".btn-close-off-canvas,.off-canvas-overlay").on('click', function () {
                $("body").removeClass('fix');
                $(".off-canvas-wrapper").removeClass('open');
            });
            /*-------- Off Canvas Open close end--------*/


            /*------- product view mode change js start -------*/
            $('.product-view-mode a').on('click', function (e) {
                e.preventDefault();
                var shopProductWrap = $('.shop-product-wrap');
                var viewMode = $(this).data('target');
                $('.product-view-mode a').removeClass('active');
                $(this).addClass('active');
                shopProductWrap.removeClass('grid-view list-view').addClass(viewMode);
            })
            /*------- product view mode change js end -------*/




            /*-------- scroll to top start --------*/
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 600) {
                    $('.scroll-top').removeClass('not-visible');
                } else {
                    $('.scroll-top').addClass('not-visible');
                }
            });
            $('.scroll-top').on('click', function (event) {
                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
            });
            /*-------- scroll to top end --------*/

            /*------- offcanvas mobile menu start -------*/
            var $offCanvasNav = $('.mobile-menu'),
                $offCanvasNavSubMenu = $offCanvasNav.find('.dropdown');

            /*Add Toggle Button With Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i></i></span>');

            /*Close Off Canvas Sub Menu*/
            $offCanvasNavSubMenu.slideUp();

            /*Category Sub Menu Toggle*/
            $offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
                var $this = $(this);
                if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
                    e.preventDefault();
                    if ($this.siblings('ul:visible').length) {
                        $this.parent('li').removeClass('active');
                        $this.siblings('ul').slideUp();
                    } else {
                        $this.parent('li').addClass('active');
                        $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                        $this.closest('li').siblings('li').find('ul:visible').slideUp();
                        $this.siblings('ul').slideDown();
                    }
                }
            });
            /*------- offcanvas mobile menu end -------*/

            /*------- Checkout Page accordion start -------*/
            $("#create_pwd").on("change", function () {
                $(".account-create").slideToggle("100");
            });

            $("#ship_to_different").on("change", function () {
                $(".ship-to-different").slideToggle("100");
            });



            // slide effect dropdown
            function dropdownAnimation() {
                $('.toggle-bar').on('show.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideDown(5000);
                });

                $('.toggle-bar').on('hide.bs.toggle-bar', function (e) {
                    $(this).find('.dropdown-list').first().stop(true, true).slideUp(5000);
                });
            }
            dropdownAnimation();


            // User Changeable Access
            var activeId = $("#instafeed"),
                myTemplate = '<div class="instagram-item"><a href="{{link}}" target="_blank" id="{{id}}"><img src="{{image}}" /></a><div class="instagram-hvr-content"><span class="tottallikes"><i class="fa fa-heart"></i>{{likes}}</span><span class="totalcomments"><i class="fa fa-comments"></i>{{comments}}</span></div></div>';

            if (activeId.length) {
                var userID = activeId.attr('data-userid'),
                    accessTokenID = activeId.attr('data-accesstoken'),

                    userFeed = new Instafeed({
                        get: 'user',
                        userId: userID,
                        accessToken: accessTokenID,
                        resolution: 'standard_resolution',
                        template: myTemplate,
                        sortBy: 'least-recent',
                        limit: 15,
                        links: false
                    });
                userFeed.run();
            }

            // Instagram feed carousel active
            $(window).on('load', function () {
                var instagramFeed = $(".instagram-carousel");
                instagramFeed.imagesLoaded(function () {
                    instagramFeed.slick({
                        slidesToShow: 6,
                        slidesToScroll: 2,
                        dots: false,
                        arrows: false,
                        responsive: [{
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 4,
                            }
                        }
                        ]
                    })
                });
            })
        }
    }
}
</script>
<style>
.lang-btn {
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}



.el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
}
</style>