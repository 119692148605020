<template>
    <div class="col-lg-3 order-2 order-lg-1">
        <aside class="sidebar-wrapper">
            <!-- single sidebar start -->
            <div class="sidebar-single">
                <h3 class="sidebar-title">{{ $t("catalog") }}</h3>
                <div class="sidebar-body">
                    <el-tree :data="types" default-expand-all @node-click="type_click"></el-tree>
                </div>
            </div>
            <!-- single sidebar end -->

            <!-- single sidebar start -->
            <div class="sidebar-single">
                <h3 class="sidebar-title">{{ $t("price") }}</h3>
                <div class="sidebar-body">
                    <div class="price-range-wrap">

                        <el-slider v-model="value" range :marks="marks" :max="9999" style="width: 90%;" input-size="small">
                        </el-slider>
                        <div class="range-slider">
                            HK$ {{ value[0] }}-- HK$ {{ value[1] }}
                            <button class="filter-btn" @click="price_range">{{ $t("filter") }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- single sidebar end -->

            <!-- single sidebar start -->
            <div class="sidebar-single">
                <h3 class="sidebar-title">{{ $t("tags") }}</h3>
                <div class="sidebar-body">
                    <ul class="checkbox-container categories-list">
                        <el-checkbox-group v-model="tags_select" size="small" @change="check_tags">
                            <li v-for="item in tags">
                                <div class="custom-control custom-checkbox">
                                    <el-checkbox :label="item.id" :key="item.id">{{ item.tagName }}</el-checkbox>
                                </div>
                            </li>
                        </el-checkbox-group>
                    </ul>
                </div>
            </div>
            <!-- single sidebar end -->





            <!-- single sidebar start -->
            <div class="sidebar-banner">
                <div class="img-container" v-for="item in banners">
                    <a href="javascript:;" @click="goto(item)">
                        <img :src="item.image" alt="">
                    </a>
                </div>
            </div>
            <!-- single sidebar end -->
        </aside>
    </div>
</template>
<script>
import * as api from "@/api/api"
export default {
    data() {
        return {
            types: [],
            tags: [],
            tags_select: [],
            value: [100, 5000],
            marks: {
                0: '0',
                9999: '9999'
            },
            banners: []
        }
    },
    props: {
        'lang': {
            type: String,
            Default: 'tc'
        },

    },
    watch: {
        'lang': {
            immediate: true,
            handler(newVal) {
                this.get_product_type()
                this.get_tags()
                this.get_banner(7)
            },
            deep: true
        },
    },
    methods: {
        get_product_type() {

            api.getRequest('/types/all',
                { lang: this.lang }).then(resp => {
                    this.types = resp.data
                    let lang = this.lang
                    this.types.forEach(function (item) {


                        if (lang == "cn") {
                            item.title = item.typeName
                        }
                        if (lang == "en") {
                            item.title = item.typeNameEn
                        }
                        if (lang == "tc") {
                            item.title = item.typeNameTc
                        }
                        if (item.children.length != 0) {
                            item.children.forEach(function (i) {
                                if (lang == "en") {
                                    i.title = i.typeNameEn
                                }
                                if (lang == "tc") {
                                    i.title = i.typeNameTc
                                }
                                if (lang == "cn") {
                                    i.title = i.typeName
                                }
                            })
                        }
                    })
                })
        },
        get_tags() {

            api.getRequest('/AllTags?type=product',
                { lang: this.lang }).then(resp => {
                    this.tags = resp.data
                })
        },
        type_click(data) {

            this.$emit('select_type', data)
            // this.$bus.$emit('pathTxt', data.title)
        },
        check_tags(value) {

        },
        price_range() {

            this.$emit('price_range', this.value)
        },
        get_banner(val) {
            api.getRequest('/banners',
                { position: val }).then(resp => {

                    var sellan = this.lang//localStorage.getItem("lang")
                    this.banners = resp.data

                    var banners = this.banners
                    banners.forEach(function (item) {
                        if (sellan == "en") {
                            item.text1 = item.text1En
                            item.text2 = item.text2En
                        }
                        if (sellan == "tc") {
                            item.text1 = item.text1Tc
                            item.text2 = item.text2Tc
                        }
                        if (sellan == "cn") {
                            item.text1 = item.text1
                            item.text2 = item.text2
                        }
                    })
                    this.banners = banners

                })
        },
        goto(item) {

            if (item.type != "function") {
                this.$router.push("/" + item.type + "/" + item.url)
            }

        }
    }
}
</script>
<style>
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #4F4AA7;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4F4AA7;
    border-color: #4F4AA7;
}

.el-slider__bar {
    height: 4px;
    background-color: #4F4AA7;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
}

.el-slider__button {
    width: 12px;
    height: 12px;
    border: 2px solid #4F4AA7;
    border-radius: 50%;
    transition: .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.el-slider__runway {
    width: 100%;
    height: 4px;
    margin: 16px 0;
    background-color: #E4E7ED;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
}
</style>