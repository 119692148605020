<template>
    <!-- Start Header Area -->
    <header class="header-area">
        <!-- main header start -->
        <div class="main-header d-none d-lg-block">
            <!-- header top start -->
            <div class="header-top bdr-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="welcome-message">
                                <p>Welcome to {{ siteName }} online store</p>
                            </div>
                        </div>
                        <div class="col-lg-6 text-right">
                            <div class="header-top-settings">
                                <ul class="nav align-items-center justify-content-end">
                                    <li class="language">
                                        <span>Language:</span>
                                        {{ langSet }}
                                        <i class="fa fa-angle-down"></i>
                                        <ul class="dropdown-list">
                                            <li v-for="item in langTitle"><a href="#" @click="setLang(item.code)">{{
                                                item.title }}</a>
                                            </li>

                                        </ul>
                                    </li>
                                    <li class="curreny-wrap">
                                        <span>Currency:</span>
                                        Hk$
                                        <i class="fa fa-angle-down"></i>
                                        <ul class="dropdown-list curreny-list">

                                            <li><a href="#"> Hk$</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- header top end -->

            <!-- header middle area start -->
            <div class="header-main-area sticky">
                <div class="container">
                    <div class="row align-items-center position-relative">

                        <!-- start logo area -->
                        <div class="col-lg-2">
                            <div class="logo">
                                <a href="index.html">
                                    <img src="assets/img/logo/pct.png" alt="">
                                </a>
                            </div>
                        </div>
                        <!-- start logo area -->

                        <!-- main menu area start -->
                        <div class="col-lg-8 position-static">
                            <div class="main-menu-area">
                                <div class="main-menu">
                                    <!-- main menu navbar start -->
                                    <nav class="desktop-menu">

                                        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal"
                                            @select="handleSelect">
                                            <el-menu-item index="'/'" @click="goto('/')">{{ $t('index.home')
                                            }}</el-menu-item>
                                            <template v-for="item, index in columns">
                                                <el-submenu :index="'/shopping/' + item.id + ''"
                                                    v-if="item.children.length != 0">
                                                    <template slot="title">{{ item.title }}</template>

                                                    <el-menu-item v-for="  i   in   item.children  "
                                                        :index="'/shopping/' + i.id + ''" @click="gotoP(i)">{{
                                                            i.title }}</el-menu-item>
                                                </el-submenu>
                                                <el-menu-item v-if="item.children.length == 0"
                                                    :index="'/shopping/' + item.id + ''" @click="gotoP(item)">{{
                                                        item.title }}</el-menu-item>

                                            </template>



                                        </el-menu>
                                    </nav>
                                    <!-- main menu navbar end -->
                                </div>
                            </div>
                        </div>
                        <!-- main menu area end -->

                        <!-- mini cart area start -->
                        <div class="col-lg-2">
                            <div class="header-configure-wrapper">
                                <div class="header-configure-area">
                                    <ul class="nav justify-content-end">
                                        <!-- <li>
                                            <a href="#" class="offcanvas-btn">
                                                <i class="lnr lnr-magnifier"></i>
                                            </a> 
                                        </li>-->
                                        <li class="user-hover">
                                            <a href="javascript:;">
                                                <i class="lnr lnr-user"></i>
                                            </a>
                                            <ul class="dropdown-list">
                                                <li><a href="#/login">登入</a></li>
                                                <li><a href="#/login">註冊</a></li>
                                                <li><a href="#/myAccount">我的賬戶</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="javascript:;" @click="goto('/favorite')">
                                                <i class="lnr lnr-heart"></i>
                                                <div class="notification">{{ wish }}</div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" class="minicart-btn" @click="my_cart">
                                                <i class="lnr lnr-cart"></i>
                                                <div class="notification">{{ cart }}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- mini cart area end -->

                    </div>
                </div>
            </div>
            <!-- header middle area end -->
        </div>
        <!-- main header start -->

        <!-- mobile header start -->
        <div class="mobile-header d-lg-none d-md-block sticky">
            <!--mobile header top start -->
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="mobile-main-header">
                            <div class="mobile-logo">
                                <a href="index.html">
                                    <img src="assets/img/logo/logo.png" alt="Brand Logo">
                                </a>
                            </div>
                            <div class="mobile-menu-toggler">
                                <div class="mini-cart-wrap">
                                    <a href="#" @click="my_cart">
                                        <i class="lnr lnr-cart"></i>
                                    </a>
                                </div>
                                <div class="mobile-menu-btn">
                                    <div class="off-canvas-btn">
                                        <i class="lnr lnr-menu"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile header top start -->
        </div>
        <!-- mobile header end -->

        <!-- off-canvas menu start -->
        <aside class="off-canvas-wrapper">
            <div class="off-canvas-overlay"></div>
            <div class="off-canvas-inner-content">
                <div class="btn-close-off-canvas">
                    <i class="lnr lnr-cross"></i>
                </div>

                <div class="off-canvas-inner">
                    <!-- search box start -->
                    <!-- <div class="search-box-offcanvas">
                        <form>
                            <input type="text" placeholder="Search Here...">
                            <button class="search-btn"><i class="lnr lnr-magnifier"></i></button>
                        </form>
                    </div> -->
                    <!-- search box end -->

                    <!-- mobile menu start -->
                    <div class="mobile-navigation">

                        <!-- mobile menu navigation start -->
                        <nav>
                            <ul class="mobile-menu">
                                <li><a href="index.html">Home</a></li>
                                <el-tree :data="types" @node-click="type_click"></el-tree>

                            </ul>
                        </nav>
                        <!-- mobile menu navigation end -->
                    </div>
                    <!-- mobile menu end -->

                    <div class="mobile-settings">
                        <ul class="nav">
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="currency" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Currency
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="currency">
                                        <a class="dropdown-item" href="#">HK$</a>

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown mobile-top-dropdown">
                                    <a href="#" class="dropdown-toggle" id="myaccount" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        My Account
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="myaccount">

                                        <a class="dropdown-item" href="#/login">登入</a>
                                        <a class="dropdown-item" href="#/login">註冊</a>
                                        <a class="dropdown-item" href="#/myAccount">我的賬戶</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- offcanvas widget area start -->
                    <div class="offcanvas-widget-area">
                        <div class="off-canvas-contact-widget">
                            <ul>
                                <li><i class="fa fa-mobile"></i>
                                    <a :href="'tel:' + tel">{{ tel }}</a>
                                </li>
                                <li><i class="fa fa-envelope-o"></i>
                                    <a :href="'mailto:' + email">{{ email }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="off-canvas-social-widget">
                            <a :href="whatapp" target="_blank"><i class="fa fa-whatsapp"></i></a>
                            <a :href="twitter" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a :href="facebook" target="_blank"><i class="fa fa-facebook"></i></a>
                            <a :href="inst" target="_blank"><i class="fa fa-instagram"></i></a>
                        </div>
                    </div>
                    <!-- offcanvas widget area end -->
                </div>
            </div>
        </aside>
        <!-- off-canvas menu end -->
        <el-drawer title="" :visible.sync="drawer" :append-to-body="true" :with-header="false" size="375px">
            <!-- Quick view modal start -->

            <div class="minicart-inner-content">
                <div class="minicart-close" @click="drawer = false">
                    <i class="lnr lnr-cross"></i>
                </div>
                <div class="minicart-content-box">
                    <div class="minicart-item-wrapper">
                        <ul>
                            <li class="minicart-item" v-for="item, index in  myCart " :key="index">
                                <div class="minicart-thumb">
                                    <a :href="'#/productDetail/' + item.id">
                                        <img :src="item.showPics" alt=" product">
                                    </a>
                                </div>
                                <div class="minicart-content">
                                    <h3 class="product-name">
                                        <a :href="'#/productDetail/' + item.id">{{ item.prdName }}</a>
                                    </h3>
                                    <p>
                                        <span class="cart-quantity">{{ item.quantity }} <strong>×</strong></span>
                                        <span class="cart-price">${{ item.price }}</span>
                                    </p>
                                </div>
                                <button class="minicart-remove" @click="delete_cart(item.id)"><i
                                        class="lnr lnr-cross"></i></button>
                            </li>
                        </ul>
                    </div>

                    <div class="minicart-pricing-box">
                        <ul>

                            <li class="total">
                                <span>total</span>
                                <span><strong>${{ total }}</strong></span>
                            </li>
                        </ul>
                    </div>

                    <div class="minicart-button">
                        <a href="#" @click="goto('/cart')"><i class="fa fa-shopping-cart"></i> view cart</a>
                        <!-- <a href="#" @click="goto('/cart')"><i class="fa fa-share"></i> checkout</a> -->
                    </div>
                </div>
            </div>
            <!-- Quick view modal end -->
        </el-drawer>


    </header>
    <!-- end Header Area -->
</template>
<script>
import * as api from "../api/api"
export default {
    data() {
        return {
            sellan: "tc",
            // lang: 0,
            drawer: false,
            columns: [],
            langTitle: [
                {
                    code: "tc",
                    title: '繁体中文',
                }
            ],
            langSet: "繁体中文",
            openItem: -1,
            showBtn: window.innerWidth <= 767,
            activeIndex: '/',
            myCart: [],
            total: 0,
            cart: 0,
            wish: 0,
            types: [],
            siteName: localStorage.getItem('siteName'),
            clientLink: this.$root.clientLink,
            email: localStorage.getItem('email'),
            tel: localStorage.getItem('tel'),
            add: localStorage.getItem('c_add'),
            facebook: localStorage.getItem('facebook'),
            twitter: localStorage.getItem('twitter'),
            inst: localStorage.getItem('inst'),
            whatapp: localStorage.getItem('whatapp'),
        }
    },
    created() {
        this.get_data()
        this.get_product_type()
        if (sessionStorage.getItem('cart')) {
            this.cart = sessionStorage.getItem('cart')

        }
        this.wish = sessionStorage.getItem('favorite')
        this.sellan = localStorage.getItem("lang")
        this.setLang(this.sellan)
        console.log("head" + this.sellan)




    }, mounted() {

        this.$bus.$on('favorite', (favorite) => {
            console.log(favorite)
            this.$shop.loadFavorite()
            this.wish = sessionStorage.getItem('favorite')

        });
        this.$bus.$on('cart', (favorite) => {
            this.$shop.loadCart()
            this.cart = sessionStorage.getItem('cart')
        });
    },

    methods: {
        setLang(val) {


            this.sellan = val
            this.$i18n.locale = val
            localStorage.setItem("lang", val)
            var lang = this.langTitle.filter(item => item.code === val)
            this.langSet = lang[0].title
            // this.get_data()
            this.$emit("switchLang", val)
            this.$bus.$emit('languageChanged', val);
            // this.get_product_type()
        },
        get_product_type() {

            api.getRequest('/types/all',
                { lang: this.lang }).then(resp => {
                    this.types = resp.data
                    let lang = this.sellan
                    this.types.forEach(function (item) {
                        // console.log(lang)

                        if (lang == "cn") {
                            item.title = item.typeName
                        }
                        if (lang == "en") {
                            item.title = item.typeNameEn
                        }
                        if (lang == "tc") {
                            item.title = item.typeNameTc
                        }
                        if (item.children.length != 0) {
                            item.children.forEach(function (i) {
                                if (lang == "en") {
                                    i.title = i.typeNameEn
                                }
                                if (lang == "tc") {
                                    i.title = i.typeNameTc
                                }
                                if (lang == "cn") {
                                    i.title = i.typeName
                                }
                            })
                        }
                    })
                })
        },
        type_click(data) {

            this.$emit('select_type', data)
            localStorage.setItem("showTitlex", data.title)
            this.$router.push("/shopping/" + data.id)
            // this.$bus.$emit('pathTxt', data.title)
        },
        get_data() {

            var sellan = this.sellan
            console.log(sellan)
            var params = {}
            api.getRequest('/types/all',
                params).then(resp => {

                    this.columns = resp.data
                    console.log(this.columns)

                    this.columns.forEach(function (item) {

                        if (sellan == "cn") {
                            item.title = item.typeName
                        }
                        if (sellan == "en") {
                            item.title = item.typeNameEn
                        }
                        if (sellan == "tc") {
                            item.title = item.typeNameTc
                        }
                        if (item.children.length != 0) {
                            item.children.forEach(function (i) {
                                if (sellan == "en") {
                                    i.title = i.typeNameEn
                                }
                                if (sellan == "tc") {
                                    i.title = i.typeNameTc
                                }
                                if (sellan == "cn") {
                                    i.title = i.typeName
                                }
                            })
                        }
                    })
                    // this.columns = newData
                    // this.setMenu()

                })
        },
        my_cart() {
            if (sessionStorage.getItem("uid")) {
                api.getRequest('/myCart',
                    { userToken: sessionStorage.getItem("uid") }).then(resp => {
                        console.log(resp)
                        if (resp.data.rs != -1) {
                            this.myCart = resp.data.data
                            this.total = resp.data.total
                            this.myCart.forEach(function (item) {
                                // console.log(item.showPics)


                            })

                            this.drawer = true
                        } else {
                            this.$message.info("请先登录");
                        }



                    })
            } else {
                this.$message.info("请先登录");
            }
        },
        delete_cart(id) {

            this.$confirm('删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/cart/' + id,
                    {}).then(resp => {
                        // console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                        this.my_cart()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        handleSelect(key, keyPath) {
            // if (key == 1) {
            //     this.$router.push("/")
            // }
            // else {
            //     this.$router.push("/shopping/" + key)
            // }
            this.activeIndex = keyPath

            // console.log(key, keyPath);
            // this.$bus.$emit('pathTxt', data.title)
        },
        goto(page) {
            this.$router.push(page);
        },
        gotoP(data) {

            localStorage.setItem("showTitlex", data.title)
            this.$router.push("/shopping/" + data.id)

        },

    }
}
</script>
<style>
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
    border-bottom: 2px solid #4F4AA7;
    color: #303133;
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #4F4AA7;
    color: #303133;
}

.el-menu.el-menu--horizontal {
    border-bottom: solid 0px #ffffff;
}
</style>