<template>
    <!-- Start My Account Area -->
    <div class="my-account-area pt--120 pb--90">
        <div class="container">
            <div class="row">
                <div class="ml-auto mr-auto col-lg-12">
                    <div class="checkout-wrapper">
                        <div id="faq" class="panel-group">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h5 class="panel-title">
                                        <span>1</span>
                                        <a data-toggle="collapse" data-parent="#faq" href="#my-account-1">Edit your
                                            account information </a>
                                    </h5>
                                </div>
                                <div id="my-account-1" class="panel-collapse collapse show">
                                    <div class="panel-body">
                                        <div class="billing-information-wrapper">
                                            <div class="account-info-wrapper">
                                                <h4>我的賬號信息</h4>
                                                <h5>個人資訊</h5>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>姓名</label>
                                                        <input type="text" v-model="user.realName">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>Email Address</label>
                                                        <input type="email" v-model="user.email">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>Telephone</label>
                                                        <input type="text" v-model="user.mobile">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="billing-back-btn">
                                                <div class="billing-back">
                                                    <a href="#">
                                                        <i class="fa fa-long-arrow-up"></i> back</a>
                                                </div>
                                                <div class="billing-btn">
                                                    <button type="submit">Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h5 class="panel-title">
                                        <span>2</span>
                                        <a data-toggle="collapse" data-parent="#faq" href="#my-account-2">Change
                                            your password </a>
                                    </h5>
                                </div>
                                <div id="my-account-2" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <div class="billing-information-wrapper">
                                            <div class="account-info-wrapper">
                                                <h4>Change Password</h4>
                                                <h5>Your Password</h5>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">
                                                        <label>Password</label>
                                                        <input type="password">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">
                                                        <label>Password Confirm</label>
                                                        <input type="password">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="billing-back-btn">
                                                <div class="billing-back">
                                                    <a href="#">
                                                        <i class="fa fa-long-arrow-up"></i> back</a>
                                                </div>
                                                <div class="billing-btn">
                                                    <button type="submit">Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h5 class="panel-title">
                                        <span>3</span>
                                        <a data-toggle="collapse" data-parent="#faq" href="#my-account-3">Modify
                                            your address book entries </a>
                                    </h5>
                                </div>
                                <div id="my-account-3" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <div class="billing-information-wrapper">
                                            <div class="account-info-wrapper">
                                                <h4>Address Book Entries</h4>
                                            </div>
                                            <div class="entries-wrapper">
                                                <div class="row" v-for="item in address">
                                                    <div
                                                        class="col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-center">
                                                        <div class="entries-info text-center">
                                                            <p>{{ item.country + '-' + item.province + '-' + item.city + '-'
                                                                +
                                                                item.section + '--ZipCode' + item.zipCode }}
                                                            </p>
                                                            <p>{{ item.consignee }} </p>
                                                            <p>{{ item.mobile }} </p>
                                                            <p>{{ item.email }} </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-center">
                                                        <div class="entries-edit-delete text-center">
                                                            <a class="edit" href="#" @click="edit_address(item)">Edit</a>
                                                            <a href="#">Delete</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="billing-back-btn">
                                                <div class="billing-back">
                                                    <a href="#">
                                                        <i class="fa fa-long-arrow-up"></i> back</a>
                                                </div>
                                                <div class="billing-btn">
                                                    <button type="button" @click="new_open">新建地址檔案</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h5 class="panel-title">
                                        <span>4</span>
                                        <a href="javascript:;" @click="goto('/favorite')">Modify your wish list </a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <el-dialog title="添加地址" :visible.sync="dialogFormVisible" :wrapper-closable="false"
                :close-on-click-modal="false">
                <form class="checkout-form">
                    <div id="billing-form" class="mb--40">


                        <div class="row">

                            <div class="col-md-6 col-12 mb--20">
                                <label>收貨人*</label>
                                <input type="text" placeholder="收貨人姓名" v-model="form.consignee">
                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>Email*</label>
                                <input type="email" placeholder="Email Address" v-model="form.email">
                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>手機號碼*</label>
                                <input type="text" placeholder="Phone number" v-model="form.mobile">
                            </div>


                            <div class="col-12 mb--20">
                                <label>詳細地址*</label>
                                <input type="text" placeholder="Address line 1" v-model="form.address">

                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>Country*</label>
                                <select class="nice-select" v-model="form.country">

                                    <option>China</option>

                                </select>
                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>Town/City*</label>
                                <input type="text" placeholder="Town/City" v-model="form.city">
                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>State*</label>
                                <input type="text" placeholder="State" v-model="form.province">
                            </div>

                            <div class="col-md-6 col-12 mb--20">
                                <label>Zip Code*</label>
                                <input type="text" placeholder="Zip Code" v-model="form.zipCode">
                            </div>


                        </div>

                    </div>
                </form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="new_address">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
    <!-- End My Account Area -->
</template>
<script>
import * as api from "../api/api"

export default {
    components: {

    },
    data() {
        return {
            user: {},
            address: [],
            dialogFormVisible: false,
            form: {},
            open_: false,
            editing: false,
        }
    },
    created() {
        this.$shop.isLogin()
        this.get_data()
        this.my_address()

    },
    methods: {
        get_data() {

            var params = {
                userToken: sessionStorage.getItem("uid"),
            }
            api.getRequest('/user/myAccount',
                params).then(resp => {

                    // console.log(resp)
                    if (resp.data.rs != -1) {
                        this.user = resp.data.data
                    }
                    else {
                        this.$message.info("请先登录");
                        this.$router.push("/login")
                    }

                })
        },
        my_address() {
            var params = {
                userToken: sessionStorage.getItem("uid")
            }
            api.getRequest('/user/myAddress/',
                params).then(resp => {
                    console.log(resp.data)

                    this.address = resp.data.records


                })
        },
        edit_address(item) {
            this.form = item
            this.dialogFormVisible = true
            this.editing = true
        },
        new_open() {
            this.form = {}
            this.dialogFormVisible = true
        },
        new_address() {
            this.form.userToken = sessionStorage.getItem("uid")

            console.log(this.form)
            if (this.editing == false) {
                api.postRequest('/user/newAddress', this.form).then(resp => {

                    if (resp.data.res == 1) {
                        this.$message.success("添加成功");
                        this.my_address();
                        this.dialogFormVisible = false
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }
            if (this.editing == true) {
                api.putRequest('/user/editAddress', this.form).then(resp => {

                    if (resp.data.res == 1) {
                        this.$message.success('修改成功');
                        this.my_address();
                        this.dialogFormVisible = false
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }

        },
        goto(page) {
            this.$router.push(page);
        },
    }
}
</script>